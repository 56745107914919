import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, DialogContentText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import SelectGroups from './SelectGroups'
import NanoDialog from './NanoDialog'

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      group_id: PropTypes.string.isRequired,
    }).isRequired
  ),
  handleValidate: PropTypes.func.isRequired,
}

const defaultProps = {
  groups: [],
  subtitle: null,
}

/**
 * This component allows to add or remove group to a resource.
 * It is necessary to provide the current list of group and a function handleValidate to execute on validation that depends of the resorucrce
 */
function AddGroupsDialog({ open, onClose, subtitle, title, groups, handleValidate }) {
  const { t } = useTranslation()
  const [accessToAdd, setAccessToAdd] = useState(groups)
  const [loading, setLoading] = useState(false)

  return (
    <NanoDialog open={open} onClose={onClose} title={title}>
      <DialogContentText>{subtitle}</DialogContentText>
      <Stack spacing={2}>
        <SelectGroups value={accessToAdd} onChange={(_, value) => setAccessToAdd(value)} />
        <LoadingButton
          type="submit"
          loadingPosition="start"
          loading={loading}
          fullWidth
          onClick={() => {
            setLoading(true)
            handleValidate(accessToAdd).finally(() => {
              setLoading(false)
              onClose()
            })
          }}
        >
          {t('validate')}
        </LoadingButton>
      </Stack>
    </NanoDialog>
  )
}

AddGroupsDialog.defaultProps = defaultProps
AddGroupsDialog.propTypes = propTypes

export default AddGroupsDialog
