import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { client } from '../apiClient'
import NanoAutocomplete from './NanoAutocomplete'
import { Stack, Typography } from '@mui/material'
import ButtonAdd from './ButtonAdd'

async function fetchOptions(search = undefined) {
  const query = { search, is_poi: true }
  const responses = await client.GET('/v1/groups', { params: { query } })
  // filter agains group already present
  return responses.data.map((group) => ({
    label: group.group_name,
    id: group.group_id,
    section: 'group',
    ...group,
  }))
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  selectAll: PropTypes.bool,
}

const defaultProps = {
  value: [],
  selectAll: false,
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function SelectGroups({ onChange, selectAll, value, ...props }) {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const fetchData = () => {
    fetchOptions().then((response) => {
      setOptions(response)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const overrideProps = {}
  overrideProps.renderGroup = (params) => {
    return (
      <Stack>
        <Stack
          sx={{ m: 2 }}
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="body1" color="text.secondary" pb={1}>
            {t('group_other')}
          </Typography>
          {selectAll && (
            <ButtonAdd
              text={t('add_all_groups')}
              onClick={() => {
                onChange(
                  null,
                  value
                    .concat(options)
                    .filter((v, index, self) => index === self.findIndex((t) => t.id === v.id))
                )
              }}
            />
          )}
        </Stack>
        {params.children}
      </Stack>
    )
  }

  return (
    <NanoAutocomplete
      label={t('group_other')}
      noOptionsText={
        <Typography sx={{ pt: 3 }} align="center">
          {t('no_groups_to_add')}
        </Typography>
      }
      fetchOptions={(search) => fetchOptions(search)}
      onChange={onChange}
      value={value}
      sx={{ maxHeight: 300, overflow: 'auto' }}
      {...props}
      {...overrideProps}
    />
  )
}

SelectGroups.propTypes = propTypes
SelectGroups.defaultProps = defaultProps

export default SelectGroups
