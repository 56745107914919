import React, { useState, useEffect, useRef, Children } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import { ExportIcon, SearchIcon, TruckIcon } from '../icons'
import { useTranslation } from 'react-i18next'
import SearchField from './SearchField'
import ButtonWhite from './ButtonWhite'
import { Button, useTheme } from '@mui/material'

// import SearchField from './SearchField'
const propTypes = {
  isExport: PropTypes.bool,
  isCalib: PropTypes.bool,
  isSearch: PropTypes.bool,
  withTabs: PropTypes.bool,
  searchFieldPlaceholder: PropTypes.string,
  searchFieldPlaceValue: PropTypes.string,
  searcFieldAction: PropTypes.func,
  searchAction: PropTypes.func,
  exportAction: PropTypes.func,
  calibAction: PropTypes.func,
  children: PropTypes.node.isRequired,
}

const defaultProps = {
  isExport: false,
  isCalib: false,
  isSearch: false,
  withTabs: false,
  searchFieldPlaceholder: null,
  searchFieldValue: null,
  searchFieldAction: null,
  calibAction: null,
  searchAction: null,
  exportAction: null,
}

function FilterBar({
  children,
  searchFieldPlaceholder,
  searchFieldValue,
  searchFieldAction, // text field
  searchAction, // button
  exportAction,
  isExport,
  isCalib,
  calibAction,
  isSearch,
  withTabs,
}) {
  const { t } = useTranslation()
  const [isSticky, setIsSticky] = useState(false)
  const tableRef = useRef(null)
  const theme = useTheme()
  // THIS block allow the sticky logic
  useEffect(() => {
    // get the current position of the Topbar and store it
    const stickyTreshold = tableRef.current.offsetTop
    const handleScroll = () => {
      if (window.pageYOffset > stickyTreshold) setIsSticky(true)
      else setIsSticky(false)
    }
    // add listener
    window.addEventListener('scroll', handleScroll)
    return () => {
      // clean listener
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [tabs, ...otherChildren] = Children.toArray(children)

  return (
    <Stack
      py={1}
      pt={0}
      direction="row"
      sx={{ ...(isSticky && { position: 'sticky', top: 0, zIndex: 99, backgroundColor: 'white' }) }}
      justifyContent={withTabs ? 'space-between' : 'flex-end'}
      ref={tableRef}
      spacing={3}
    >
      {withTabs && (
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          {tabs}
        </Stack>
      )}

      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
        {withTabs ? otherChildren : children}

        {isSearch &&
          (searchFieldAction ? (
            <SearchField
              placeholder={t(searchFieldPlaceholder)}
              setValue={searchFieldAction}
              value={searchFieldValue}
            />
          ) : (
            <ButtonWhite
              variant="outlined"
              text={t('search')}
              startIcon={<SearchIcon />}
              onClick={searchAction}
            />
          ))}
        {isExport && (
          <ButtonWhite
            variant="outlined"
            text={t('export_csv')}
            startIcon={<ExportIcon />}
            onClick={exportAction}
          />
        )}

        {isCalib && (
          <Button
            variant="outlined"
            color="pending"
            onClick={calibAction}
            sx={{ p: 0, minWidth: '40px' }}
          >
            <TruckIcon color={theme.palette.pending.main} />
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

FilterBar.propTypes = propTypes
FilterBar.defaultProps = defaultProps

export default FilterBar
