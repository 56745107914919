const minHoursBeforeOrder = (t) =>
  Object.freeze([
    {
      name: t('data_type_unit', { value: { value: 0.5, unit: 'day' } }),
      value: '12',
    },
    {
      name: t('data_type_unit', { value: { value: 1, unit: 'day' } }),
      value: '24',
    },
    {
      name: t('data_type_unit', { value: { value: 1.5, unit: 'day' } }),
      value: '36',
    },
    {
      name: t('data_type_unit', { value: { value: 2, unit: 'day' } }),
      value: '48',
    },
    {
      name: t('data_type_unit', { value: { value: 2.5, unit: 'day' } }),
      value: '60',
    },
    {
      name: t('data_type_unit', { value: { value: 3, unit: 'day' } }),
      value: '72',
    },
  ])

export default minHoursBeforeOrder
