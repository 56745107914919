import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { client } from '../../../shared/apiClient'
import NanoSelectDateRange from '../../../shared/components/NanoSelectDateRange'
import NanoSelectMultiple from '../../../shared/components/NanoSelectMultiple'
import FilterBar from '../../../shared/components/FilterBar'
import CalibrationsTable from '../../../shared/components/CalibrationsTable'
import { useGroupStore, useUserStore } from '../../../shared/store'
import dayjs from 'dayjs'

const optionsSources = (t) => [
  { name: t('api'), value: 'external' },
  { name: t('export'), value: 'export' },
  { name: t('menu_users'), value: 'manual,delivery' },
]
const propTypes = {
  device: PropTypes.string,
}

const defaultProps = { device: null }

function AdminCalibPage({ device }) {
  const [dataDevice, setDataDevice] = useState([])
  const [filters, setFilters] = useState(null)
  const [dateFilter, setDateFilter] = useState([null, dayjs()])
  const [typeFilter, setTypeFilter] = useState([])
  const [deviceFilter, setDeviceFilter] = useState([])
  const [groupFilter, setGroupFilter] = useState([])
  const [userFilter, setUserFilter] = useState([])
  const [sourceFilter, setSourceFilter] = useState([])
  const [searched, setSearched] = useState('')
  const [dataLoading, setDataLoading] = useState(true)
  const dataGroup = useGroupStore((state) => state.dataGroup)
  const dataUser = useUserStore((state) => state.dataUser)
  const { t } = useTranslation()

  const getData = async () => {
    setDataLoading(true)
    return (
      useGroupStore.getState().fetchData(),
      useUserStore.getState().fetchData(),
      client
        .GET('/v1/devices', { params: {} })
        .then((response) => {
          setDataDevice(response.data)
        })
        .finally(() => {
          setDataLoading(false)
        })
    )
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let query = ''
    if (device) query += `&idDevice=${device}`
    if (deviceFilter.length) query += `&idDevice=${deviceFilter.join(',')}`
    if (groupFilter.length) query += `&group_ids=${groupFilter.join(',')}`
    if (userFilter.length) {
      if (userFilter.find((u) => u === 'nanolike')) {
        // query += `&created_by nanostaff`
      }
      if (userFilter.filter((u) => u !== 'nanolike').length)
        query += `&created_by_id=${userFilter.filter((u) => u !== 'nanolike').join(',')}`
    }
    if (sourceFilter.length) query += `&source=${sourceFilter.join(',')}`
    if (dateFilter.filter((date) => date !== null).length === 2) {
      const endDate = dateFilter[1].format()
      query += `&start_date=${dateFilter[0].toDate()}&end_date=${new Date(endDate)}`
    }
    if (typeFilter.length) query += `&type=${typeFilter.join(',')}`
    if (searched) query += `&search=${searched}`
    setFilters(query)
  }, [
    deviceFilter,
    dateFilter,
    typeFilter,
    groupFilter,
    userFilter,
    sourceFilter,
    searched,
    device,
  ])

  return (
    <>
      <FilterBar
        isSearch
        searchFieldPlaceholder="search"
        searchFieldAction={setSearched}
        searchFieldValue={searched}
      >
        <NanoSelectDateRange
          placeholder="date"
          value={dateFilter}
          handleSelect={(value) => {
            setDateFilter(value)
          }}
          dateRangePickerProps={{ disableFuture: true, minDate: dayjs().subtract(2, 'year') }}
        />
        <NanoSelectMultiple
          placeholder="type"
          options={[
            { name: t('level'), value: 'level' },
            { name: t('delivery'), value: 'delivery' },
          ]}
          value={typeFilter}
          handleSelect={(value) => {
            setTypeFilter(value)
          }}
        />
        {!device && !dataLoading && (
          <NanoSelectMultiple
            placeholder="bin_one"
            options={dataDevice?.map((d) => ({
              name: `${d.device_name} ${d.farm_name ? `- ${d.farm_name}` : ''}`,
              value: d.device_reference,
            }))}
            value={deviceFilter}
            handleSelect={(value) => {
              setDeviceFilter(value)
            }}
          />
        )}
        {!device && !dataLoading && (
          <NanoSelectMultiple
            placeholder="group_other"
            options={dataGroup?.map((g) => ({ name: `${g.group_name}`, value: g.group_id }))}
            value={groupFilter}
            handleSelect={(value) => {
              setGroupFilter(value)
            }}
          />
        )}
        {!device && !dataLoading && (
          <NanoSelectMultiple
            placeholder="done_by"
            options={dataUser
              ?.filter((u) => u.first_name !== null && u.last_name !== null)
              ?.map((u) => ({ name: `${u.first_name} ${u.last_name}`, value: u.idUser }))
              .concat({ name: 'Nanolike', value: 'nanolike' })}
            value={userFilter}
            handleSelect={(value) => {
              setUserFilter(value)
            }}
          />
        )}
        {!device && !dataLoading && (
          <NanoSelectMultiple
            placeholder="source"
            options={optionsSources(t)}
            value={sourceFilter}
            handleSelect={(value) => {
              setSourceFilter(value)
            }}
          />
        )}
      </FilterBar>
      <CalibrationsTable filters={filters} withDevice={!!device} />
    </>
  )
}

AdminCalibPage.propTypes = propTypes
AdminCalibPage.defaultProps = defaultProps

export default AdminCalibPage
