import React, { useEffect, useState } from 'react'
import LevelWithTonsAndMissingWeight from '../../../shared/components/LevelWithTonsAndMissingWeight'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Box,
  Stack,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  Button,
} from '@mui/material'
import DeviceTitle from '../../../shared/components/DeviceTitle'
import NanoSelectSingle from '../../../shared/components/NanoSelectSingle'
import DeviceContentTypeRules from './components/DeviceContentTypeRules'
import { decimalReg } from '../../../shared/utils/parseUtils'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import SelectDeviceContent from '../../../shared/components/SelectDeviceContent'
const propTypes = {
  useFieldArrayIndex: PropTypes.number.isRequired,
  control: PropTypes.shape({}).isRequired,
  order: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  device: PropTypes.shape({}).isRequired,
  graphData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workspaceOrderData: PropTypes.shape({
    deviceContents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        device_content: PropTypes.string.isRequired,
      })
    ).isRequired,
    deviceContentTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        device_content_type: PropTypes.string.isRequired,
        min_quantity_tons: PropTypes.number,
      })
    ).isRequired,
    drugs: PropTypes.arrayOf(PropTypes.string),
    supplements: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  showDrugs: PropTypes.bool,
  showSupplements: PropTypes.bool,
  onClickRemoveFromOrder: PropTypes.func,
}

const defaultProps = {
  showDrugs: true,
  showSupplements: true,
}

function OrderFormStepWhatForm({
  useFieldArrayIndex,
  order,
  device,
  graphData,
  workspaceOrderData,
  control,
  showDrugs,
  showSupplements,
  watch,
  onClickRemoveFromOrder,
}) {
  const { t } = useTranslation()
  const [deviceContentTypeSelected, setDeviceContentTypeSelected] = useState(null)
  const state = useWorkspaceSettingsStore()
  const rounding = parseInt(state.getSetting('rounding_precision')?.value) ?? 2
  const orderDevice = order.silos?.find((s) => s.id === device.device_reference)
  const selectedContentType = watch(`silos[${useFieldArrayIndex}].what_content_type`)

  const levelDataToday = graphData?.find((d) => d.data_type === 'level')?.data_points[0]

  const levelPredictions = graphData?.find((d) => d.data_type === 'levelPredictions')?.data_points
  let levelDataSelectedDate = null
  if (levelPredictions) {
    const dateDayJS = dayjs(order.date)
    const nbDays = dateDayJS.diff(dayjs().startOf('day'), 'day')
    if (nbDays > 1 && nbDays <= 4) {
      const levelPrediction = levelPredictions[nbDays - 1]
      if (levelPrediction) {
        levelDataSelectedDate = {
          ...levelPrediction,
          // @TODO: inject missingWeight in levelPredictions data_types
          missingWeight: graphData?.find((d) => d.data_type === `missingWeightJPlus${nbDays}`)
            ?.data_points[0]?.value,
        }
      }
    }
  }

  useEffect(() => {
    if (selectedContentType) {
      const deviceContentType = workspaceOrderData?.deviceContentTypes?.find(
        (obj) => obj.device_content_type === selectedContentType
      )
      setDeviceContentTypeSelected(deviceContentType)
    }
  }, [selectedContentType, workspaceOrderData?.deviceContentTypes])

  return (
    <Card sx={{ m: '16px', width: 500 }}>
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <DeviceTitle
              {...device}
              deviceContent={device.device_content}
              group_poi={{
                group_poi_name: device.farm_name,
              }}
            />
            <Stack direction="row" spacing={5}>
              <Stack alignItems="center" spacing={1}>
                <Typography variant="h6" color="text.secondary">
                  {t()}
                </Typography>
                <LevelWithTonsAndMissingWeight level={levelDataToday} />
              </Stack>
              {levelDataSelectedDate ? (
                <Stack alignItems="center" spacing={1}>
                  <Typography variant="h6" color="text.secondary">
                    {dayjs(order.date).format('MMM D')}
                  </Typography>
                  <LevelWithTonsAndMissingWeight level={levelDataSelectedDate} />
                </Stack>
              ) : null}
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Controller
              control={control}
              defaultValue={orderDevice?.content?.name ?? device.device_content ?? ''}
              name={`silos[${useFieldArrayIndex}].what_content`}
              rules={{ required: t('form_field_required_error_message') }}
              shouldUnregister
              render={({ field, fieldState: { error } }) => (
                <SelectDeviceContent
                  multiple={false}
                  required
                  groupFilter={
                    state.getSetting('filterContentByGroup')?.value === 'true'
                      ? device?.farm_id
                      : null
                  }
                  label={t('device_content')}
                  error={!!error}
                  helperText={error?.message}
                  onChange={(_, data) => {
                    field.onChange(data?.label)
                  }}
                  value={field.value}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={orderDevice?.content?.type ?? ''}
              name={`silos[${useFieldArrayIndex}].what_content_type`}
              rules={{ required: t('form_field_required_error_message') }}
              shouldUnregister
              render={({ field, fieldState: { error } }) => (
                <NanoSelectSingle
                  options={workspaceOrderData?.deviceContentTypes?.map((deviceContentType) => ({
                    value: deviceContentType?.device_content_type,
                    name: deviceContentType?.device_content_type,
                  }))}
                  label={t('device_content_type')}
                  variant="outlined"
                  {...field}
                  required
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            {showDrugs ? (
              <Controller
                control={control}
                defaultValue={orderDevice?.content?.drug ?? ''}
                name={`silos[${useFieldArrayIndex}].what_drug`}
                shouldUnregister
                render={({ field, fieldState: { error } }) => (
                  <NanoSelectSingle
                    options={workspaceOrderData?.drugs?.map((drug) => ({
                      value: drug.drug,
                      name: drug.drug,
                    }))}
                    label={t('drug')}
                    variant="outlined"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            ) : null}
            {showSupplements ? (
              <Controller
                control={control}
                defaultValue={orderDevice?.content?.supplement?.name ?? ''}
                name={`silos[${useFieldArrayIndex}].what_supplement`}
                shouldUnregister
                render={({ field, fieldState: { error } }) => (
                  <NanoSelectSingle
                    options={workspaceOrderData?.supplements?.map((supplement) => ({
                      value: supplement.supplement,
                      name: supplement.supplement,
                    }))}
                    label={t('supplements')}
                    variant="outlined"
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            ) : null}
            <Controller
              control={control}
              defaultValue={orderDevice?.comment ?? ''}
              name={`silos[${useFieldArrayIndex}].what_comment`}
              shouldUnregister
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('comment')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Divider sx={{ pt: 1 }} />
            <Controller
              control={control}
              defaultValue={orderDevice?.tonnage ?? ''}
              name={`silos[${useFieldArrayIndex}].what_tonnage`}
              shouldUnregister
              rules={{
                valueAsNumber: true,
                validate: (value) => {
                  const valueNumber = Number(value)
                  if (value > device.capa_max * 1.3) {
                    return t('calib_form_capa_max', { count: device.capa_max })
                  }
                  if (typeof deviceContentTypeSelected?.min_quantity_tons === 'number') {
                    if (valueNumber < deviceContentTypeSelected?.min_quantity_tons) {
                      return t(
                        'order_form_step_what_content_type_rule_error_only_min_quantity_rules',
                        {
                          device_content_type: deviceContentTypeSelected.device_content_type,
                          min_quantity_tons: t('number_workspace_filling_unit', {
                            value: deviceContentTypeSelected.min_quantity_tons,
                          }),
                        }
                      )
                    }
                  }
                },
                ...(rounding !== 0 && {
                  pattern: {
                    value: decimalReg(rounding),
                    message: t('decimal_allowed', { count: rounding }),
                  },
                }),
                required: t('form_field_required_error_message'),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('string_workspace_filling_unit', {
                    value: t('order_form_step_what_quantity_input_title'),
                  })}
                  type="number"
                  required
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Box sx={{ pl: 2 }}>
              <Stack>
                <DeviceContentTypeRules deviceContentType={deviceContentTypeSelected} />
              </Stack>
            </Box>
            <Box display="flex" justifyContent="center" sx={{ paddingTop: 2 }}>
              <Button
                variant="text"
                style={{ fontSize: '12px' }}
                sx={{ color: 'error.main', width: '100%' }}
                onClick={onClickRemoveFromOrder}
              >
                {t('order_form_step_what_remove_from_order')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

OrderFormStepWhatForm.propTypes = propTypes
OrderFormStepWhatForm.defaultProps = defaultProps

export default OrderFormStepWhatForm
