import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, Typography, Skeleton } from '@mui/material'
import { dateMediumWithoutTime } from '../utils/dateUtils'

const propTypes = {
  loading: PropTypes.bool,
  date: PropTypes.string,
}

const defaultProps = { loading: false, date: '' }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function DateCell({ loading, date }) {
  const specificDate = new Date(date)
  return (
    <TableCell align="left">
      <Typography variant="body1">
        {loading ? <Skeleton width="80%" /> : date ? dateMediumWithoutTime(specificDate) : '-'}
      </Typography>
    </TableCell>
  )
}

DateCell.propTypes = propTypes
DateCell.defaultProps = defaultProps

export default DateCell
