import React from 'react'
// import PropTypes from 'prop-types'
import Sidebar from './Sidebar'
import { styled } from '@mui/material'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSessionStore } from '../../shared/store'
import ErrorPage from '../../shared/components/ErrorPage'
import * as Sentry from '@sentry/react'

const Main = styled('main')(({ theme }) => ({
  padding: theme.spacing(0, 3, 0, 3),
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}))

const propTypes = {}

const defaultProps = {}

/**
 * Everything under layout must be with authenticatio
 */
const Layout = () => {
  const user = useSessionStore((state) => state.user)
  const location = useLocation()
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  if (!user) return <Navigate to="/login" state={{ from: location }} replace />
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <Main>
        <Sentry.ErrorBoundary fallback={<ErrorPage />} key={location.pathname}>
          <Outlet />
        </Sentry.ErrorBoundary>
      </Main>
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
