import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import TopPage from '../layout/TopPage'
import AdminDataPage from './data/AdminDataPage'
import AdminCalibPage from './calibrating/AdminCalibPage'
import SupportPage from '../../shared/components/SupportPage'
import AdminCombined from './combined/AdminCombined'
import { useLocation, useNavigate } from 'react-router-dom'
import AdminDeliveryPage from './delivery/AdminDeliveryPage'
import { useUserStore } from '../../shared/store'
import AdminGroupPage from '../group/AdminGroupPage'

const propTypes = {}

const defaultProps = {}

const CustomTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': { display: 'flex', backgroundColor: 'transparent' },
  '& button': {
    padding: '10px 16px 10px 16px',
    borderRadius: 100,
    backgroundColor: 'transparent',
    color: '#0F0B37',
    height: 52,
    border: '1px solid #C5C5D3',
  },
  '& button.Mui-selected': { backgroundColor: '#0F0B37', color: 'white', border: 'transparent' },
}))

function Admin() {
  // TEMP SEARCH LOCAL NOT SUPPORTED BY WEB SERVICE
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [value, setValue] = useState(Number(new URLSearchParams(location.search).get('tab')) ?? 0)
  const users = useUserStore((state) => state.dataUser)
  const handleChange = (e, newValue) => {
    navigate(`/admin?tab=${newValue}`)
    setValue(newValue)
  }

  useEffect(() => {
    if (!users.length) useUserStore.getState().fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const valueInfo = [
    {
      label: t('group_other'),
      page: <AdminGroupPage />,
    },
    {
      label: t('admin_data_tab'),
      page: <AdminDataPage />,
    },
    {
      label: t('admin_order_tab'),
      page: <AdminDeliveryPage />,
    },
    {
      label: t('calibrating'),
      page: <AdminCalibPage />,
    },
    {
      label: t('combined_bins_title'),
      page: <AdminCombined />,
    },
    {
      label: t('admin_support_tab'),
      page: <SupportPage />,
    },
  ]
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%' }}>
      <TopPage title={t('menu_admin')} />

      <CustomTabs variant="scrollable" value={value} onChange={handleChange} sx={{ py: 1 }}>
        {valueInfo.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </CustomTabs>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          paddingTop: 24,
        }}
      >
        {valueInfo[value].page}
      </div>
    </div>
  )
}

Admin.propTypes = propTypes
Admin.defaultProps = defaultProps

export default Admin
