import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Order from '../components/Order'
import { Controller } from 'react-hook-form'
import { Box, TextField } from '@mui/material'
import NanoSection from '../../../shared/components/NanoSection'

const propTypes = {
  order: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
}

const defaultProps = {}

function OrderFormResume({ order, control }) {
  const { t } = useTranslation()
  return (
    <Box sx={{ width: '80%' }}>
      <Order order={order} fromForm />
      <NanoSection title={t('comment')}>
        <Controller
          control={control}
          name="resume_comment"
          defaultValue={order.comment ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              placeholder={t('order_form_resume_comment_placeholder')}
            />
          )}
        />
      </NanoSection>
    </Box>
  )
}

OrderFormResume.propTypes = propTypes
OrderFormResume.defaultProps = defaultProps

export default OrderFormResume
