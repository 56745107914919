import React, { useContext, useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getRequest, postRequest } from '../../shared/apiClient'
import { ToastContext } from '../../shared/contexts'
import NanoPassword from '../../shared/components/NanoPassword'
import { useNavigate } from 'react-router-dom'

const propTypes = {}

const defaultProps = {}

function ResetPassword() {
  const [loading, setloading] = useState(false)
  const toastContext = useContext(ToastContext)
  const navigate = useNavigate()
  const [isValid, setIsValid] = useState(false)
  const { t } = useTranslation()
  const { handleSubmit, control } = useForm()
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  useEffect(() => {
    setloading(true)
    getRequest(`internal/reset-user-password?token=${token}`)
      .then(() => setloading(false))
      .catch((err) => {
        setloading(false)
        toastContext.sendMessage(err.message, 'error')
        navigate('/login', { replace: true })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])
  const onSubmit = handleSubmit((data) => {
    setloading(true)
    postRequest(`internal/reset-user-password?token=${token}`, data)
      .then(() => {
        setloading(false)
        toastContext.sendMessage(t('password_change_success'))
        setTimeout(() => {
          navigate('/login')
        }, 3000)
      })
      .catch((err) => {
        setloading(false)
        toastContext.sendMessage(err.message, 'error')
      })
  })
  return (
    <>
      <Typography variant="h1">{t('set_a_new_password')}</Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <Controller
          control={control}
          name="new_password"
          defaultValue=""
          rules={{ required: t('form_field_required_error_message') }}
          render={({ field, fieldState: { error } }) => (
            <NanoPassword
              required
              {...field}
              label={t('new_password')}
              error={!!error}
              helperText={error?.message}
              validatePassword
              isValid={setIsValid}
            />
          )}
        />
        <LoadingButton loading={loading} disabled={!isValid} type="submit" fullWidth>
          {t('validate')}
        </LoadingButton>
      </Box>
    </>
  )
}

ResetPassword.propTypes = propTypes
ResetPassword.defaultProps = defaultProps

export default ResetPassword
