import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, List, Typography, Divider } from '@mui/material'
import { postRequest } from '../../../shared/apiClient'
import OrderFormStepWhatForm from './OrderFormStepWhatForm'
import { useWorkspaceSettingsStore } from '../../../shared/store'

const propTypes = {
  order: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  watch: PropTypes.func.isRequired,
  setDisabledNextButton: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  workspaceOrderData: PropTypes.shape({
    deviceContents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        device_content: PropTypes.string.isRequired,
      })
    ).isRequired,
    deviceContentTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        device_content_type: PropTypes.string.isRequired,
        min_quantity_tons: PropTypes.number,
      })
    ).isRequired,
    drugs: PropTypes.arrayOf(PropTypes.string),
    supplements: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

const defaultProps = {}

function OrderFormStepWhat({
  order,
  control,
  workspaceOrderData,
  fields,
  remove,
  watch,
  setDisabledNextButton,
}) {
  const [loading, setLoading] = useState(true)
  const [devicesGraphData, setDevicesGraphData] = useState([])
  const [totalTonnage, setTotalTonnage] = useState(0)
  const { t } = useTranslation()

  const state = useWorkspaceSettingsStore()
  const showDrugs = state.getSetting('showDrugs')?.value === 'true'
  const showSupplements = state.getSetting('showSupplements')?.value === 'true'

  const getData = async () => {
    setDisabledNextButton(true)
    setLoading(true)
    if (order.devicesSelected && order.devicesSelected?.length) {
      const deviceIds = order.devicesSelected?.map((d) => d.device_reference)
      await postRequest('v1/get-graph-data', {
        device_ids: deviceIds,
        data_types: [
          'level',
          'levelPredictions',
          // @TODO: inject missingWeight in levelPredictions data_types
          'missingWeightJPlus1',
          'missingWeightJPlus2',
          'missingWeightJPlus3',
          'missingWeightJPlus4',
        ],
        is_last_value: true,
      }).then((response) => {
        setDevicesGraphData(response.data?.data)
      })
    }
    if (order.totalTonnage) {
      setTotalTonnage(order.totalTonnage)
    }
    setLoading(false)
    setDisabledNextButton(false)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const whatTonnages = fields.map((device, index) => watch(`silos[${index}].what_tonnage`))

  useEffect(() => {
    if (whatTonnages) {
      setTotalTonnage(
        whatTonnages
          .map((item) => parseFloat(item))
          .filter((item) => !isNaN(Number(item)))
          .reduce((total, num) => total + num, 0)
      )
    }
  }, [whatTonnages])

  return (
    <div>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        // @TODO: add logic to scroll directly to errors
        <div>
          <List sx={{ marginBottom: 5 }}>
            {fields.map((device, index) => {
              return (
                <OrderFormStepWhatForm
                  key={device.id}
                  control={control}
                  watch={watch}
                  showDrugs={showDrugs}
                  showSupplements={showSupplements}
                  onClickRemoveFromOrder={() => remove(index)}
                  // we need the order to get default values
                  order={order}
                  // we need the index to give a dynamic name to the Controllers
                  useFieldArrayIndex={index}
                  // we need the device to display name, farm name, capacity and content
                  device={device}
                  // we need the graphData to display the current level and the level corresponding to the delivery date
                  graphData={devicesGraphData.filter(
                    (d) => d.device_reference === device.device_reference
                  )}
                  // we need these data for drop-down lists
                  workspaceOrderData={workspaceOrderData}
                />
              )
            })}
          </List>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              textAlign: 'center',
              width: '100%',
              backgroundColor: 'white',
              zIndex: 1,
            }}
          >
            <Divider light />
            <Typography variant="h2" sx={{ padding: 2 }}>
              {t('order_form_step_what_total_quantity', {
                totalTonnage: t('number_workspace_filling_unit', { value: totalTonnage }),
              })}
            </Typography>
          </Box>
        </div>
      )}
    </div>
  )
}

OrderFormStepWhat.propTypes = propTypes
OrderFormStepWhat.defaultProps = defaultProps

export default OrderFormStepWhat
