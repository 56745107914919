import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import NanoMenuItem from './NanoMenuItem'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'
import { ArrowDownLongIcon, ArrowUpLongIcon, SortIcon } from '../icons/index'
import { IconButton, Stack, useTheme } from '@mui/material'

const propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    name: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSelect: PropTypes.func.isRequired,
  withGroups: PropTypes.bool,
}

const defaultProps = { withGroups: false }

function NanoSelectSort({ value, handleSelect, options, withGroups, ...props }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [asc, setAsc] = useState(true)
  const [selected, setSelected] = useState(value)
  const theme = useTheme()

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleChange = (event) => {
    const isAsc = event.target.value.value === selected.value ? !asc : asc
    setAsc(isAsc)
    setSelected(event.target.value)
    handleSelect(event.target.value, isAsc)
  }

  return (
    <Select
      {...props}
      open={open}
      value={selected}
      displayEmpty
      IconComponent={ArrowDownIcon}
      sx={{ bgcolor: 'white', height: 40 }}
      MenuProps={{
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'left',
          vertical: -24,
        },
      }}
      onOpen={() => setOpen(true)}
      onChange={handleChange}
      onClose={() => setOpen(false)}
      renderValue={() => (
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <SortIcon />
          <Typography variant="h5">{t('sort')}</Typography>
        </Stack>
      )}
    >
      {options
        .filter((option) => withGroups || option.name !== 'farm_sort')
        .map((option) => [
          <NanoMenuItem
            key={option.value}
            listItemTextProps={{
              primary: t(
                option.unit
                  ? t('string_workspace_filling_unit', { value: t(option.name) })
                  : t(option.name)
              ),
            }}
            value={option}
          >
            {/* @FIXME : replace the icon button and trigger the onChange for the same value : https://github.com/mui/material-ui/pull/20361 */}
            <IconButton
              sx={{ p: 0, m: 0 }}
              onClick={() => {
                setAsc(!asc)
                handleSelect(selected, !asc)
              }}
            >
              {option.value === value.value ? (
                !asc ? (
                  <ArrowDownLongIcon width="21" height="21" stroke={theme.palette.secondary.main} />
                ) : (
                  <ArrowUpLongIcon width="21" height="21" stroke={theme.palette.secondary.main} />
                )
              ) : null}
            </IconButton>
          </NanoMenuItem>,
        ])}
    </Select>
  )
}

NanoSelectSort.propTypes = propTypes
NanoSelectSort.defaultProps = defaultProps

export default NanoSelectSort
