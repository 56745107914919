import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stack, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import NanoDialog from '../../shared/components/NanoDialog'
import { useSessionStore } from '../../shared/store'

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const defaultProps = {
  isOpen: false,
}

function FeedbackModal({ isOpen, onClose, onSuccess }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const { user, currentClient } = useSessionStore((state) => state)
  const sendMessageToSlack = async (body) => {
    const url = 'https://hooks.slack.com/services/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'

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    }

    fetch(url, requestOptions)
      .then((response) => {
        setLoading(false)
        onClose()
        onSuccess(true)
      })
      .catch((error) => {
        setLoading(false)
        onClose()
        onSuccess(false)
        console.error('error', error)
      })
  }

  const onSubmit = async () => {
    setLoading(true)

    const body = {
      username: 'Feedback V2',
      icon_emoji: ':bulb:',
      channel: 'binconnectv2-feedbacks',
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text:
              `Client: *${currentClient.name}*\n` +
              `Send by: *${user.first_name} ${user.last_name}*\n ${user.email}\n` +
              `Message: ${value}\n`,
          },
        },
      ],
    }
    sendMessageToSlack(body)
  }
  return (
    <NanoDialog open={isOpen} title={t('share_feedback')} onClose={onClose}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          multiline
          rows={3}
          placeholder={t('feedback_ph')}
          autoFocus
          value={value}
          onChange={(event) => setValue(event.target.value)}
          required
        />
        <LoadingButton
          // loading={isLoading}
          type="submit"
          loadingPosition="start"
          fullWidth
          loading={loading}
          onClick={onSubmit}
        >
          {t('confirm_and_send')}
        </LoadingButton>
      </Stack>
    </NanoDialog>
  )
}

FeedbackModal.propTypes = propTypes
FeedbackModal.defaultProps = defaultProps

export default FeedbackModal
