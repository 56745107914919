import { Box, Paper } from '@mui/material'
import React from 'react'
import siloBackground from '../../../shared/images/silo.png'
import PropTypes from 'prop-types'
import LoadingPage from '../../../shared/components/LoadingPage'
import LangComponent from '../../../shared/components/LangComponent'
import { BinConnectLogo } from '../../../shared/icons'

const propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
}

const defaultProps = {
  loading: false,
  children: null,
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const Background = (props) => {
  if (props.loading) return <LoadingPage />
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: 'primary.main',
        backgroundImage: `url(${siloBackground})`,
        backgroundBlendMode: 'hard-light',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: 'top',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ p: 5, position: 'absolute', top: 0, left: 0, opacity: { xs: 0, sm: 100 } }}>
        <BinConnectLogo />
      </Box>
      <Paper
        sx={{
          p: 3,
          minWidth: '33%',
          position: { xs: 'relative', sm: 'static' },
          pt: { xs: 10, sm: 3 },
        }}
      >
        <LangComponent
          sx={{
            '& svg': { xs: {}, sm: { fill: 'white' } },
            '& .MuiInputBase-root': { xs: {}, sm: { color: 'white' } },
            position: 'absolute',
            top: 0,
            right: 0,
            width: 'auto',
            p: 5,
          }}
        />
        {props.children}
      </Paper>
    </Box>
  )
}

Background.propTypes = propTypes
Background.defaultProps = defaultProps

export default Background
