import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isKnownWarningCause, WARNING_CAUSE } from '../models/operationSuggested'
import { IceTrappedIcon, InfoIcon } from '../icons/index'
import QuickInfo from './QuickInfo'

const WARNING_CAUSE_ICON = Object.freeze({
  [WARNING_CAUSE.ICE]: IceTrappedIcon,
})

const WARNING_CAUSE_SHORT = Object.freeze({
  [WARNING_CAUSE.ICE]: null,
})

const WARNING_CAUSE_TITLE = Object.freeze({
  [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_title',
})

const WARNING_CAUSE_EXPLANATION = Object.freeze({
  [WARNING_CAUSE.ICE]: 'device_warning_cause_ice_explanation',
})

const getWarningCauseTitle = (t, warningCause) => {
  // The day we add links to docs, we will use something like this
  // return (
  //   <Trans
  //     i18nKey='test_tags'
  //     values={{ href: 'value' }}
  //     components={{ link: <a href='https://doclink...' rel='noreferrer' /> }}
  //   />
  // )
  return t(WARNING_CAUSE_TITLE[warningCause] ?? 'device_status_problem_unknown_title')
}

const getWarningCauseExplanation = (t, warningCause) => {
  return t(WARNING_CAUSE_EXPLANATION[warningCause] ?? 'device_status_problem_unknown_explanation', {
    product: 'BinConnect',
  })
}

const getWarningCauseIcon = (warningCause) => {
  return WARNING_CAUSE_ICON[warningCause] ?? InfoIcon
}

const propTypes = {
  warningCause: PropTypes.string,
}

function WarningCauseInfo({ warningCause }) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (!warningCause || !isKnownWarningCause(warningCause)) return null

  const Icon = getWarningCauseIcon(warningCause)
  const short = WARNING_CAUSE_SHORT[warningCause] && t(WARNING_CAUSE_SHORT[warningCause])
  const explanation = getWarningCauseExplanation(t, warningCause)
  const title = getWarningCauseTitle(t, warningCause)

  // The case may not be handled yet
  if (!explanation || !title) return null

  return (
    <QuickInfo
      short={short}
      ShortIcon={InfoIcon}
      title={title}
      Icon={Icon}
      explanation={explanation}
      color={theme.palette.warning.main}
    />
  )
}

WarningCauseInfo.propTypes = propTypes

export { getWarningCauseExplanation, getWarningCauseTitle, getWarningCauseIcon }
export default WarningCauseInfo
