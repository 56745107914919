import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BugIllustration, LogoutIcon } from '../icons'
import { useSessionStore } from '../store'
import { useTheme } from '@mui/material/styles'
const propTypes = {
  error: PropTypes.shape({}),
  resetError: PropTypes.func,
}

const defaultProps = { error: {}, resetError: () => console.log('no resetError set') }

function ErrorPage({ error, resetError }) {
  console.error({ error })
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    resetError()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      height="100vh"
      alignItems="center"
      justifyContent="center"
      spacing={12}
      padding={12}
    >
      <Grid item container direction="column" spacing={5} xs={6}>
        <Grid item>
          <Typography variant="h1" fontSize={40}>
            {t('error_oops')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h1" fontSize={50}>
            {t('error_title')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">{t('error_subtitle')}</Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={() => {
                navigate('/')
              }}
            >
              {t('back_to_dashboard')}
            </Button>
            <Button
              endIcon={<LogoutIcon stroke={theme.palette.secondary.main} />}
              variant="text"
              onClick={() => {
                resetError()
                useSessionStore.getState().logout()
              }}
            >
              {t('logout_menu')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid item>
        <BugIllustration />
      </Grid>
    </Grid>
  )
}

ErrorPage.propTypes = propTypes
ErrorPage.defaultProps = defaultProps

export default ErrorPage
