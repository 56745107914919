import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import FilterBar from '../../shared/components/FilterBar'
import MoreAction from '../../shared/components/MoreAction'
import NanoSelectSort from '../../shared/components/NanoSelectSort'
import NanoTable from '../../shared/components/NanoTable'
import SimpleCell from '../../shared/components/SimpleCell'
import { ToastContext } from '../../shared/contexts'
import { useGroupStore } from '../../shared/store'
import AdminGroupModal from './AdminGroupModal'

const propTypes = {}
const defaultProps = {}

const columnsGroup = (handleEdit) => {
  return [
    {
      header: 'group_one',
      width: 800,
      format: (row) => <SimpleCell loading={row.loading} data={row.group_name} />,
    },
    {
      header: 'id_group',
      width: 400,
      format: (row) => <SimpleCell loading={row.loading} data={row.client_poi_id} />,
    },
    {
      header: 'comment',
      width: 200,
      format: (row) => <SimpleCell loading={row.loading} data={row.comment} />,
    },
    {
      header: 'actions',
      format: (row) => <MoreAction onClickEdit={() => handleEdit(row)} />,
      width: 55,
    },
  ]
}

function AdminGroupPage() {
  const [dataLoading, setDataLoading] = useState(false)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [data, setData] = useState([])
  const [asc, setAsc] = useState(true)
  const [searched, setSearched] = useState('')
  const [sortValue, setSortValue] = useState({ value: 'group_name', name: 'group_one' })
  const [defaultValues, setDefaultValues] = useState({})
  const toastContext = useContext(ToastContext)
  const { t } = useTranslation()

  const options = [
    { value: 'group_name', name: 'group_one' },
    { value: 'client_poi_id', name: 'id_group' },
  ]

  const dataGroup = useGroupStore((state) => state.dataGroup)

  const fetchData = () => {
    if (!dataGroup.length) {
      if (!dataLoading) setDataLoading(true)
      useGroupStore
        .getState()
        .fetchData()
        .finally(() => {
          setDataLoading(false)
        })
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGroup])

  const updateGroup = () => {
    setData(dataGroup)
  }

  const handleEdit = (row) => {
    setDefaultValues({ ...row })
    setFormIsOpen(true)
  }

  useEffect(() => {
    setData(
      dataGroup
        .filter(
          (group) =>
            group.group_name.toLowerCase().includes(searched.toLowerCase()) ||
            group?.client_poi_id?.toLowerCase().includes(searched.toLowerCase())
        )
        .sort((a, b) => {
          const aValue = a[sortValue.value] || null
          const bValue = b[sortValue.value] || null

          if (aValue === null && bValue === null) {
            return 0
          }

          if (aValue === null) {
            return asc ? -1 : 1
          }

          if (bValue === null) {
            return asc ? 1 : -1
          }

          return asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
        })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched, sortValue, asc])

  return (
    <>
      <FilterBar
        isSearch
        searchFieldPlaceholder="search"
        searchFieldAction={setSearched}
        searchFieldValue={searched}
      >
        <NanoSelectSort
          value={sortValue}
          options={options}
          handleSelect={(value, asc) => {
            setSortValue(value)
            setAsc(asc)
          }}
        />
      </FilterBar>

      <NanoTable columns={columnsGroup(handleEdit)} data={data} loading={dataLoading} />

      {formIsOpen && (
        <AdminGroupModal
          isOpen={formIsOpen}
          row={defaultValues}
          onSuccess={(res) => {
            toastContext.sendMessage(t('admin_snackbar_group_updated'))
            const newData = [...dataGroup]
            setSearched('')
            const index = newData.findIndex((group) => group.group_id === res.group_id)
            newData[index] = res
            useGroupStore.getState().setData(newData)
            updateGroup()
          }}
          onClose={() => {
            setFormIsOpen(false)
            setDefaultValues({})
          }}
        />
      )}
    </>
  )
}

AdminGroupPage.propTypes = propTypes
AdminGroupPage.defaultProps = defaultProps

export default AdminGroupPage
