import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import NanoMenuItem from './NanoMenuItem'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'
import { Button } from '@mui/material'
import { CloseIcon } from '../icons/index'

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      icon: PropTypes.element,
      name: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  /** @type {PropTypes.Requireable<import('@mui/material').TextFieldVariants>} */
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  defaultValue: PropTypes.shape({
    value: PropTypes.any,
    name: PropTypes.string,
  }),
  handleClear: PropTypes.bool,
}

const defaultProps = {
  placeholder: '',
  label: '',
  /** @type {import('@mui/material').TextFieldVariants} */
  variant: 'standard',
  width: null,
  height: null,
  backgroundColor: null,
  defaultValue: {},
  handleClear: false,
}

/**
 * @type {import('react').ForwardRefExoticComponent<PropTypes.InferProps<propTypes> &  Omit<import('@mui/material').TextFieldProps, 'variant'>>}
 */
const NanoSelectSingle = forwardRef(
  (
    {
      variant,
      defaultValue,
      options,
      label,
      placeholder,
      width,
      height,
      backgroundColor,
      handleClear,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    return (
      <TextField
        ref={ref}
        fullWidth
        select
        variant={variant}
        defaultValue={defaultValue}
        label={label}
        placeholder={placeholder}
        SelectProps={{
          IconComponent: ArrowDownIcon,
          sx: {
            backgroundColor: { backgroundColor },
            width: { width },
            height: { height },
          },
        }}
        InputProps={variant === 'standard' ? { disableUnderline: true } : null} // à modifier
        {...props}
      >
        {options?.map((option) => (
          <NanoMenuItem key={option.name} value={option.value} icon={option.icon}>
            {t(option.name)}
          </NanoMenuItem>
        ))}
        {handleClear && ( // FIXME : should not manage the button as a menu item here
          <NanoMenuItem key="clear" value={null}>
            <Button color="grey" endIcon={<CloseIcon />}>
              {t('clear')}
            </Button>
          </NanoMenuItem>
        )}
      </TextField>
    )
  }
)

NanoSelectSingle.propTypes = propTypes
NanoSelectSingle.defaultProps = defaultProps

export default NanoSelectSingle
