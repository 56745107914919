import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import NanoPaper from './NanoPaper'
import { Grid, IconButton, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { CopyIcon, PoiIcon } from '../icons/index'
import { ToastContext } from '../contexts'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  data: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})])
  ).isRequired,
  loading: PropTypes.bool,
}

const defaultProps = { columns: [], loading: false }

function NanoCharacteristics({ columns, loading }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const toastContext = useContext(ToastContext)
  return (
    <NanoPaper>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {columns.map((d, index) => (
          <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
            <Typography variant="h6" color="text.secondary">
              {t(d.title)}
            </Typography>
            <Typography variant="body1">
              {loading ? (
                <Skeleton width="80%" />
              ) : (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {d.renderValue || typeof d.renderValue === 'string' ? (
                    <Typography noWrap>{d.renderValue || t('unknown')} </Typography>
                  ) : (
                    d.renderValue
                  )}
                  {d.copy && (
                    <IconButton
                      sx={{ backgroundColor: 'grey.main', width: 40, height: 40 }}
                      onClick={() => {
                        navigator.clipboard.writeText(d.renderValue)
                        toastContext.sendMessage(t('copied_to_clipboard'))
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  )}
                  {/* Very specific for gps coordinates, maybe reworkd or hardcoded in @see {DeviceChararteristics} */}
                  {d.gmaps && (
                    <IconButton
                      sx={{ backgroundColor: 'grey.main', width: 40, height: 40 }}
                      onClick={() => {
                        const [lat, lng] = d.renderValue.trim().split(',')
                        window.open(
                          `https://www.google.com/maps/search/?q=${lat},${lng}&ll=${lat},${lng}&z=13`,
                          '_blank'
                        )
                      }}
                    >
                      <PoiIcon color={theme.palette.text.primary} />
                    </IconButton>
                  )}
                </Stack>
              )}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </NanoPaper>
  )
}

NanoCharacteristics.defaultProps = defaultProps
NanoCharacteristics.propTypes = propTypes
// #endregion

export default NanoCharacteristics
