import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography, Popover, useTheme, useMediaQuery, Box } from '@mui/material'

const propTypes = {
  /** Short version of the info */
  short: PropTypes.string,
  /** Default to Icon */
  ShortIcon: PropTypes.elementType,

  /** Long version of the info */
  title: PropTypes.string,
  explanation: PropTypes.string,
  Icon: PropTypes.elementType,

  /** Color of the info */
  color: PropTypes.string,
}

function QuickInfo({ short, title, explanation, color, Icon, ShortIcon = Icon }) {
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)

  return (
    <Stack
      spacing={0.5}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {ShortIcon && <ShortIcon color={color} height={24} width={24} />}
      {!mobileFormat && short && (
        <Typography variant="h5" color={color} style={{ wordBreak: 'break-word', marginLeft: 8 }}>
          {short}
        </Typography>
      )}
      {!mobileFormat && title && explanation && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box p={3} maxWidth={400}>
            <Stack direction="row" justifyContent="flex-start" spacing={1}>
              <Icon height={24} width={24} />
              <Typography variant="h4" color="text.primary" pb={2}>
                {title}
              </Typography>
            </Stack>
            <Typography variant="body1" color="text.secondary">
              {explanation}
            </Typography>
          </Box>
        </Popover>
      )}
    </Stack>
  )
}

QuickInfo.propTypes = propTypes

export default QuickInfo
