import PropTypes from 'prop-types'
import { useSessionStore } from '../store'

const propTypes = {
  fallbackComponent: PropTypes.element,
}

const defaultProps = {
  fallbackComponent: null,
}

const AccessRoleGroupComponent = ({ children, fallbackComponent }) => {
  const me = useSessionStore((state) => state.user)
  return me.group_memberships?.length > 0 || me.workspace_role.global ? children : fallbackComponent
}
AccessRoleGroupComponent.propTypes = propTypes
AccessRoleGroupComponent.defaultProps = defaultProps

export default AccessRoleGroupComponent
