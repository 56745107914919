import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import UserAvatarAndName from './UserAvatarAndName'

const propTypes = {
  loading: PropTypes.bool,
}

const defaultProps = { loading: false }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function CreatedByCell({ loading, ...user }) {
  return (
    <TableCell align="left">
      <UserAvatarAndName {...user} loading={loading} />
    </TableCell>
  )
}

CreatedByCell.propTypes = propTypes
CreatedByCell.defaultProps = defaultProps

export default CreatedByCell
