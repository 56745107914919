import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Skeleton, Stack, useMediaQuery, useTheme, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PoiIcon } from '../icons/PoiIconBold.svg'
import { ReactComponent as SiloIcon } from '../icons/SiloIconBold.svg'
import { ContentIcon, LinkIcon } from '../icons/index'

const propTypes = {
  loading: PropTypes.bool,
  device_name: PropTypes.string,
  capa_max: PropTypes.number,
  group_poi: PropTypes.shape({
    group_poi_name: PropTypes.string,
    group_poi_id: PropTypes.string,
  }),
  deviceContent: PropTypes.string,
  is_combined: PropTypes.bool,
  deviceDetail: PropTypes.bool,
  has_parent_combined_device: PropTypes.bool,
}

const defaultProps = {
  loading: false,
  capa_max: null,
  group_poi: { group_poi_name: '???' },
  deviceContent: '',
  device_name: '',
  is_combined: false,
  deviceDetail: false,
  has_parent_combined_device: false,
}

function DeviceTitle({
  loading,
  device_name: deviceName,
  capa_max: capaMax,
  group_poi: groupPoi,
  deviceContent,
  is_combined: isCombined,
  has_parent_combined_device: childrenCombined,
  deviceDetail,
}) {
  const { t } = useTranslation()
  const variant = deviceDetail ? 'body1' : 'body2'
  const notApplicable = t('not_applicable')
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))

  const { ref: deviceContentRef, isOverflowing: overflowDeviceContent } = useIsOverflowing()
  const { ref: groupNameRef, isOverflowing: overflowGroupName } = useIsOverflowing()
  const { ref: deviceNameRef, isOverflowing: overflowDeviceName } = useIsOverflowing()

  return (
    <Grid container spacing={0} direction="column">
      {!deviceDetail && (
        <Grid item container alignContent="flex-start" alignItems="center">
          <Grid item xs={10}>
            <Stack direction="row" spacing={0.5} alignItems="stretch">
              <Tooltip title={overflowDeviceName && deviceName}>
                <Typography ref={deviceContentRef} noWrap variant="body1">
                  {loading ? <Skeleton width={80} /> : deviceName}
                </Typography>
              </Tooltip>
              {isCombined && <LinkIcon />}
            </Stack>
          </Grid>
        </Grid>
      )}
      {(!mobileFormat || deviceDetail) && (
        <>
          {!childrenCombined && (
            <Grid item container spacing={1}>
              <Grid item alignItems="center" display="flex">
                <PoiIcon color={theme.palette.text.secondary} />
              </Grid>
              <Grid item xs={8}>
                <Tooltip title={overflowGroupName && groupPoi.group_poi_name}>
                  <Typography ref={groupNameRef} noWrap variant={variant} color="text.secondary">
                    {loading ? <Skeleton /> : groupPoi.group_poi_name}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          <Grid item container spacing={1}>
            <Grid item alignItems="center" display="flex">
              <SiloIcon />
            </Grid>
            <Grid item xs={8}>
              <Typography noWrap variant={variant} color="text.secondary">
                {loading ? (
                  <Skeleton />
                ) : (
                  t('device_caracteristic_capacity_max_with_count', {
                    count: t('number_workspace_filling_unit', { value: capaMax }),
                  })
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item alignItems="center" display="flex">
              <ContentIcon />
            </Grid>
            <Grid item xs={!deviceDetail ? 8 : 10}>
              <Tooltip title={overflowDeviceContent && deviceContent}>
                <Typography
                  ref={deviceNameRef}
                  noWrap={!deviceDetail}
                  variant={variant}
                  color="text.secondary"
                >
                  {loading ? <Skeleton /> : deviceContent || notApplicable}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const isOverflowActive = (event) => {
  return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth
}

/**
 * Hook to watch if an element is overflowing
 */
function useIsOverflowing() {
  const [isOverflowing, setOverflowing] = useState(false)
  const ref = useRef()

  const handleResize = () => {
    setOverflowing(isOverflowActive(ref.current))
  }

  const observerRef = useRef(new ResizeObserver(handleResize))

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observerRef.current.disconnect()
    }
  }, [])

  const setDeviceContentRef = (node) => {
    if (node) {
      ref.current = node
      observerRef.current.observe(node)
      handleResize()
    }
  }

  return { ref: setDeviceContentRef, isOverflowing }
}

DeviceTitle.propTypes = propTypes
DeviceTitle.defaultProps = defaultProps

export default DeviceTitle
