import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  pt: PropTypes.number,
}

const defaultProps = { title: null, pt: 3 }

function NanoSection({ title, pt, children }) {
  return (
    <Grid item container direction="column" spacing={2} paddingTop={pt}>
      {!!title && (
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>
      )}
      <Grid item>{children}</Grid>
    </Grid>
  )
}

NanoSection.propTypes = propTypes
NanoSection.defaultProps = defaultProps

export default NanoSection
