import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Skeleton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import isNumber from '../utils/isNumber'
import { ClockIcon } from '@mui/x-date-pickers'
import i18n from '../../config/i18n'

const propTypes = {
  loading: PropTypes.bool,
  lastMessageReceived: PropTypes.string,
}
const defaultProps = { loading: false }

function LastData({ loading, lastMessageReceived }) {
  const { t } = useTranslation()
  const [format, setFormat] = useState('minutes')
  const [lastData, setLastData] = useState(null)

  useEffect(() => {
    if (lastMessageReceived) {
      let lastData = Math.round(
        (new Date().getTime() - new Date(lastMessageReceived).getTime()) / 1000 / 60
      )
      if (lastData >= 60) {
        lastData = Math.round(lastData / 60)
        setFormat('hours')
        if (lastData >= 24) {
          lastData = Math.round(lastData / 24)
          setFormat('days')
        }
      }
      setLastData(lastData)
    }
  }, [lastMessageReceived])

  const lastDataCorrect = lastData
    ? new Intl.RelativeTimeFormat(i18n.resolvedLanguage, { numeric: 'auto' }).format(
        -lastData,
        format
      )
    : null

  return (
    <Stack display="flex" direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
      {loading ? (
        <Skeleton variant="rounded" width={24} height={24} />
      ) : (
        lastDataCorrect && (
          <>
            <ClockIcon />
            <Typography variant="h5">
              {loading ? (
                <Skeleton />
              ) : isNumber(lastData) ? (
                t('last_message', { time: lastDataCorrect })
              ) : (
                '-'
              )}
            </Typography>
          </>
        )
      )}
    </Stack>
  )
}

LastData.propTypes = propTypes
LastData.defaultProps = defaultProps

export default LastData
