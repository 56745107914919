import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import { ReactComponent as AddIcon } from '../icons/AddIcon.svg'

const propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}
// Sett required and no default because if a  button we need an action
const defaultProps = { text: '' }

function ButtonAdd({ text, onClick, ...props }) {
  return (
    <Button startIcon={<AddIcon stroke="white" />} onClick={onClick} {...props}>
      {text}
    </Button>
  )
}

ButtonAdd.propTypes = propTypes
ButtonAdd.defaultProps = defaultProps

export default ButtonAdd
