import React from 'react'
import { TableCell } from '@mui/material'
import Delivery from '../../../shared/components/Delivery'

const propTypes = {}
const defaultProps = {}

function DeliveryCell({ ...row }) {
  return (
    <TableCell sx={{ pr: 3, pl: 3 }}>
      <Delivery {...row} />
    </TableCell>
  )
}

DeliveryCell.propTypes = propTypes
DeliveryCell.defaultProps = defaultProps

export default DeliveryCell
