import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, TableCell } from '@mui/material'
import QuickActions from '../../device/components/QuickActions'

const propTypes = {
  device_name: PropTypes.string,
  status: PropTypes.string,
  metadata: PropTypes.shape({}),
  loading: PropTypes.bool,
  device_reference: PropTypes.string,
  device_id: PropTypes.string,
  capa_max: PropTypes.number,
  group_poi: PropTypes.shape({
    group_poi_name: PropTypes.string,
    group_poi_id: PropTypes.string,
  }),
  device_install_date: PropTypes.string,
  displayOrder: PropTypes.bool,
  adminMode: PropTypes.func,
}
const defaultProps = {
  device_name: null,
  remaining_days: null,
  status: null,
  device_id: null,
  device_reference: null,
  capa_max: null,
  handleEdit: null,
  loading: false,
  group_poi: null,
  displayOrder: true,
  adminMode: null,
}

function ActionCell({
  displayOrder,
  device_id: deviceId,
  device_name: deviceName,
  status,
  metadata,
  device_reference: deviceRef,
  capa_max: capaMax,
  device_install_date: installDate,
  loading,
  group_poi: group,
  adminMode,
}) {
  return (
    <Box>
      <TableCell sx={{ p: 1 }}>
        <Stack spacing={2} direction="row" flexWrap="wrap" justifyContent="flex-end" useFlexGap>
          <QuickActions
            device={{
              device_reference: deviceRef,
              capa_max: capaMax,
              metadata,
              status,
              device_name: deviceName,
              group_poi: group,
              device_id: deviceId,
              device_install_date: installDate,
            }}
            fromDashboard
            loading={loading}
            displayOrder={displayOrder}
            adminMode={adminMode}
          />
        </Stack>
      </TableCell>
    </Box>
  )
}

ActionCell.defaultProps = defaultProps
ActionCell.propTypes = propTypes

export default ActionCell
