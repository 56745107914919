import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from '@mui/x-date-pickers-pro'
import { CalendarIcon } from '../icons/index'
import { useTheme } from '@mui/material'

const propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
}

const defaultProps = { value: null }

function NanoSelectDate({ value, onChange, ...props }) {
  const theme = useTheme()

  return (
    <DatePicker
      slots={{
        openPickerIcon: () => (
          <CalendarIcon height={24} width={24} fill={theme.palette.text.secondary} />
        ),
      }}
      slotProps={{ textField: { required: true } }}
      value={value ?? {}}
      onChange={onChange}
      {...props}
    />
  )
}

NanoSelectDate.propTypes = propTypes
NanoSelectDate.defaultProps = defaultProps

export default NanoSelectDate
