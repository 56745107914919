import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { client } from '../../../shared/apiClient'
import DeviceList from '../../device/components/DeviceList'
import { Stack, Typography } from '@mui/material'
import ButtonAdd from '../../../shared/components/ButtonAdd'
import { LinkIcon } from '../../../shared/icons/index'
import NanoSection from '../../../shared/components/NanoSection'
import { useTranslation } from 'react-i18next'
import AdminCombinedModal from './AdminCombinedModal'
import { ToastContext } from '../../../shared/contexts'

const propTypes = {
  device: PropTypes.string,
}

const defaultProps = { device: null }

function AdminCombined() {
  const [dataLoading, setDataLoading] = useState(true)
  const [dataCombined, setDataCombined] = useState([])
  const [rowCount, setRowCount] = useState(null)
  const [page, setPage] = useState(1)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)

  const getData = async () => {
    setDataLoading(true)
    const query = { page: 1, page_size: 15, is_combined: true }
    return client
      .GET('/v2/dashboard', { params: { query } })
      .then((response) => {
        const { results, ...pagination } = response.data
        setDataCombined(results)
        setRowCount(pagination.rowCount)
        setPage(pagination.page)
      })
      .finally(() => {
        setDataLoading(false)
      })
  }

  const loadMoreRows = () => {
    const query = { page: page + 1, page_size: 15, is_combined: true }
    client.GET('/v2/dashboard', { params: { query } }).then((response) => {
      const { results, ...pagination } = response.data
      setDataCombined([...dataCombined, ...results])
      setPage(pagination.page)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        alignContent="flex-start"
        justifyContent="space-between"
      >
        <Typography variant="body1" color="black">
          {t('admin_explanation_combined')}
          <ul>
            <li>{t('admin_explanation_combined_first')}</li>
            <li>{t('admin_explanation_combined_second')}</li>
          </ul>
        </Typography>
      </Stack>
      <Stack
        sx={{ pb: 2 }}
        direction="row"
        alignItems="center"
        alignContent="flex-start"
        justifyContent="space-between"
      >
        <NanoSection title={t('combined_bins_list')} />
        <ButtonAdd
          text={t('combine')}
          onClick={() => {
            setFormIsOpen(true)
          }}
          startIcon={<LinkIcon stroke="white" />}
        />
      </Stack>
      <DeviceList
        data={dataCombined}
        loading={dataLoading}
        remoteRowCount={rowCount}
        fetchPage={loadMoreRows}
        adminMode={getData}
      />

      {formIsOpen && (
        <AdminCombinedModal
          isOpen={formIsOpen}
          onSuccess={() => {
            toastContext.sendMessage(t('admin_combined_snackbar_alert_created'))
            getData()
          }}
          onClose={() => {
            setFormIsOpen(false)
          }}
        />
      )}
    </>
  )
}

AdminCombined.propTypes = propTypes
AdminCombined.defaultProps = defaultProps

export default AdminCombined
