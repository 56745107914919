import React from 'react'

import PropTypes from 'prop-types'
import { TextField, InputAdornment } from '@mui/material'
import { ReactComponent as SearchIcon } from '../icons/SearchIcon.svg'

const propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
}

const defaultProps = { placeholder: '', value: null }

function SearchField({ placeholder, value, setValue }) {
  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        sx: { height: 40 },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}

SearchField.propTypes = propTypes
SearchField.defaultProps = defaultProps

export default SearchField
