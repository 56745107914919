import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as NotFound } from '../icons/NotFoundImage.svg'
const propTypes = {}

const defaultProps = {}

function NotFoundPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Grid container height="100%" alignItems="center" spacing={12} padding={12}>
      <Grid item container direction="column" spacing={5} xs={6}>
        <Grid item>
          <Typography variant="h1" fontSize={40}>
            404
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h1" fontSize={50}>
            {t('not_found_title')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">{t('not_found_subtitle')}</Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => navigate('devices')}>{t('back_to_dashboard')}</Button>
        </Grid>
      </Grid>
      <Grid item>
        <NotFound />
      </Grid>
    </Grid>
  )
}

NotFoundPage.propTypes = propTypes
NotFoundPage.defaultProps = defaultProps

export default NotFoundPage
