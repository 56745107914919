import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { DialogContentText, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import NanoDialog from './NanoDialog'

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickConfirm: PropTypes.func,
  noDescripton: PropTypes.bool,
  deleteMode: PropTypes.bool,
}

const defaultProps = {
  open: false,
  onClickCancel: null,
  onClickConfirm: null,
  noDescripton: false,
  deleteMode: true,
}

function DialogConfirmAction({
  open,
  title,
  description,
  onClickCancel,
  onClickConfirm,
  noDescripton,
  deleteMode,
}) {
  const { t } = useTranslation()
  return (
    <NanoDialog
      open={open}
      onClose={onClickCancel}
      title={title}
      actions={[
        onClickCancel ? (
          <Button key="cancelActionBtn" onClick={onClickCancel} color="grey" size="medium">
            {t('cancel')}
          </Button>
        ) : null,
        onClickConfirm ? (
          <Button
            key="deleteActionBtn"
            startIcon={deleteMode && <DeleteIcon />}
            onClick={onClickConfirm}
            autoFocus
            color={deleteMode ? 'error' : 'secondary'}
            size="medium"
          >
            {t('confirm')}
          </Button>
        ) : null,
      ]}
    >
      {!noDescripton && (
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      )}
    </NanoDialog>
  )
}

DialogConfirmAction.propTypes = propTypes
DialogConfirmAction.defaultProps = defaultProps

export default DialogConfirmAction
