import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MoreAction from '../../shared/components/MoreAction'
import { TableCell } from '@mui/material'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

const propTypes = {
  handleUpdateOrDelete: PropTypes.func.isRequired,
}

const defaultProps = {}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function MoreActionAlerts({ handleUpdateOrDelete }) {
  const { t } = useTranslation()

  return (
    <TableCell align="right" onClick={(e) => e.stopPropagation()}>
      <MoreAction
        deleteItemFunction={() => handleUpdateOrDelete('delete')}
        deleteItemAlertTitle={t('alert_confirm_deletion_title')}
        deleteItemAlertDescription={t('alert_confirm_deletion_description')}
        onClickEdit={() => handleUpdateOrDelete('update')}
      />
    </TableCell>
  )
}

MoreActionAlerts.propTypes = propTypes
MoreActionAlerts.defaultProps = defaultProps

export default MoreActionAlerts
