import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getRequest } from '../apiClient'
import NanoAutocomplete from './NanoAutocomplete'
import { IconButton, Stack, Typography } from '@mui/material'
import { AddIcon } from '../icons'
import UserAvatarAndName from './UserAvatarAndName'

/**
 * Get all the users if
 * @param {string} search filter the result based on the search, override the filterAccessGroups, not mandatory with no pagination. But we still use it because it works anyway
 * @param {string[]} filterAccessGroups id of groups de filter against
 * @returns
 */
async function fetchOptions(search = undefined, filterAccessGroups = []) {
  const params = {
    page_size: 1000, // for now no pagination and local treatment because not a lot of users. @TODO: add pagination and new ability in the webservice to filter by group / group name
    search,
  }
  const response = await getRequest('v1/users', { params })
  const users =
    filterAccessGroups.length && (!search || !search.length)
      ? response.data.results.filter(
          (u) =>
            u.group_memberships
              .map((g) => g.group_id)
              .some((gid) => filterAccessGroups.includes(gid)) ||
            u.workspace_role.workspace_role_id === 'Admin'
        )
      : response.data.results

  return users
    .map((u) => ({
      label: u.first_name ? `${u.first_name} ${u.last_name}` : u.email,
      id: u.idUser,
      section:
        u.workspace_role.workspace_role_id === 'Admin'
          ? 'admin'
          : u.group_memberships.length
            ? filterAccessGroups.length
              ? u.group_memberships.find((gm) => filterAccessGroups.includes(gm.group_id)).group
                  .group_name
              : u.group_memberships[0].group.group_name
            : 'other',
      ...u,
    }))
    .sort((a, b) => (a.section > b.section ? 1 : -1))
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      section: PropTypes.string,
    })
  ),
  filterAccessGroups: PropTypes.arrayOf(PropTypes.string.isRequired),
}

const defaultProps = {
  error: null,
  value: null,
  required: false,
  filterAccessGroups: [],
}

function SelectUserOrGroupOfUser({ filterAccessGroups, onChange, error, value, required }) {
  const { t } = useTranslation()
  return (
    <NanoAutocomplete
      label={t('alert_form_select_user_label')}
      fetchOptions={(v) => fetchOptions(v, filterAccessGroups)}
      onChange={onChange}
      error={error}
      value={value}
      required={required}
      noOptionsText={
        <Typography sx={{ pt: 3 }} align="center">
          {t('no_users_to_add')}
        </Typography>
      }
      textFieldProps={{
        placeholder: t('alert_form_select_user_placeholder'),
        helperText: error?.message ?? t('alert_form_select_user_helpertext'),
      }}
      renderOption={(props, option) => {
        return (
          <Stack component="li" direction="row" justifyContent="space-between" {...props}>
            <UserAvatarAndName withEmail {...option} />
            <IconButton
              sx={{ backgroundColor: 'secondary.main' }}
              onClick={(event) => {
                onChange(event, value ? value.concat([option]) : [option])
                event.stopPropagation()
              }}
            >
              <AddIcon stroke="white" />
            </IconButton>
          </Stack>
        )
      }}
    />
  )
}

SelectUserOrGroupOfUser.propTypes = propTypes
SelectUserOrGroupOfUser.defaultProps = defaultProps

export default SelectUserOrGroupOfUser
