import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import batteryFilterModel from '../models/batteryFilterModel'
import { Button, IconButton, Select, Stack, Typography } from '@mui/material'
import NanoMenuItem from './NanoMenuItem'
import { ArrowDownIcon, CloseIcon, OkIcon } from '../icons/index'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'

const InlineBadge = styled(Badge)(({ theme }) => ({
  paddingRight: theme.spacing(4),
  '& .MuiBadge-badge': {
    right: 12,
    top: 10,
  },
}))

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
}

const defaultProps = {
  value: null,
}

/**
 *@param {(PropTypes.InferProps<SelectBattery.propTypes> )} props
 */
function SelectBattery({ onChange, value, ...props }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(value)

  const clearAll = () => {
    setSelected(undefined)
    setOpen(false)
    onChange(undefined)
  }

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleChange = (event) => {
    setSelected(event.target.value)
    onChange(event.target.value)
  }

  return (
    <Select
      {...props}
      open={open}
      value={selected}
      displayEmpty
      IconComponent={ArrowDownIcon}
      sx={{ bgcolor: 'white', height: 40 }}
      MenuProps={{
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'left',
          vertical: -24,
        },
      }}
      onOpen={() => setOpen(true)}
      onChange={handleChange}
      onClose={() => setOpen(false)}
      renderValue={() => (
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          {selected ? (
            <InlineBadge color="secondary" badgeContent={1}>
              <Typography variant="h5">{t('battery')}</Typography>
            </InlineBadge>
          ) : (
            <Typography variant="h5">{t('battery')}</Typography>
          )}
        </Stack>
      )}
    >
      {batteryFilterModel.map((battery) => {
        return (
          <NanoMenuItem
            key={battery.value}
            listItemTextProps={{ primary: t(battery.name) }}
            value={battery.value}
          >
            <IconButton sx={{ p: 0, m: 0 }}>
              {battery.value === selected ? (
                <OkIcon width="21" height="21" stroke={theme.palette.secondary.main} />
              ) : null}
            </IconButton>
          </NanoMenuItem>
        )
      })}
      <Stack direction="row" pt={2} justifyContent="center">
        <Button color="grey" onClick={clearAll} endIcon={<CloseIcon />}>
          {t('clear')}
        </Button>
      </Stack>
    </Select>
  )
}

SelectBattery.propTypes = propTypes
SelectBattery.defaultProps = defaultProps

export default SelectBattery
