const OPERATION_SUGGESTED = Object.freeze({
  CONNECT_SENSOR: 'connect_sensor',
  MOVE: 'move',
  NEW_BATTERY: 'new_battery',
  NEW_DEVICE_FULL: 'new_device_full',
  NEW_ELEC: 'new_elec',
  NEW_SENSOR: 'new_sensor',
  // NEW_TAPPING: 'new_tapping',
  OUT_OF_DATA: 'out_of_data',
  SHIM: 'shim',
  TRAPPED_IN_ICE: 'trapped_in_ice',
})

const WARNING_CAUSE = Object.freeze({
  ICE: 'ice',
})

function isKnownOperationSuggested(operationSuggested) {
  return Object.values(OPERATION_SUGGESTED).includes(operationSuggested)
}

function isKnownWarningCause(warningCause) {
  return Object.values(WARNING_CAUSE).includes(warningCause)
}

export { OPERATION_SUGGESTED, WARNING_CAUSE, isKnownOperationSuggested, isKnownWarningCause }
