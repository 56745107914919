import React from 'react'
import PropTypes from 'prop-types'
import NanoAvatar from './NanoAvatar'
import { Grid, Skeleton, Typography, useTheme } from '@mui/material'
import { useSessionStore } from '../store'
import { useTranslation } from 'react-i18next'

const avatarSizeDef = {
  large: {},
  normal: { width: 32, height: 32 },
  small: { width: 24, height: 24 },
}
/**
 *
 * @param {import('@mui/material').Theme} theme
 * @returns
 */
const avatarColorDef = (theme) => ({
  // The important is only usefull when the avatart is the icon of a chip.
  0: {
    backgroundColor: theme.palette.priorityHigh.main,
    color: `${theme.palette.priorityHigh.contrastText} !important`,
  },
  1: {
    backgroundColor: theme.palette.priorityMedium.main,
    color: `${theme.palette.priorityMedium.contrastText} !important`,
  },
  2: {
    backgroundColor: theme.palette.decorative.main,
    color: `${theme.palette.decorative.contrastText} !important`,
  },
  3: {
    backgroundColor: theme.palette.avatarBlue.main,
    color: `${theme.palette.avatarBlue.contrastText} !important`,
  },
  default: {},
})

const propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  idUser: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  /** size of the avatar only use with avatar only : small | normal | large */
  avatarSize: PropTypes.oneOf(['small', 'normal', 'large']),
  avatarOnly: PropTypes.bool,
  /** display the mail below the name use more space force avatarSize = large */
  withEmail: PropTypes.bool,
  withPhone: PropTypes.bool,
  /** role use to change the color display */
  workspace_role: PropTypes.shape({
    workspace_role_id: PropTypes.string,
    hierarchy: PropTypes.number,
    global: PropTypes.bool,
    display_name: PropTypes.string,
  }),
  loading: PropTypes.bool,
}

const defaultProps = {
  first_name: '',
  last_name: '',
  email: '--',
  phone_number: '-',
  avatarOnly: false,
  withEmail: false,
  withPhone: false,
  loading: false,
  idUser: null,
  avatarSize: 'large',
}

/**
 * Reprenset a user with its Avatar, we can display only the avatar with avatarOnly props
 * @param {PropTypes.InferProps<propTypes>} props
 */
function UserAvatarAndName({
  first_name: firstName,
  last_name: lastName,
  email,
  phone_number: phone,
  idUser,
  avatarOnly,
  withEmail,
  withPhone,
  loading,
  avatarSize,
  workspace_role: workspaceRole,
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const me = useSessionStore((state) => state.user)
  const username = firstName ? `${firstName} ${lastName || ''}` : email
  return avatarOnly ? (
    <NanoAvatar
      loading={loading}
      sx={{
        ...avatarSizeDef[avatarSize],
        ...avatarColorDef(theme)[
          workspaceRole?.hierarchy < 0 ? 0 : Math.trunc(workspaceRole?.hierarchy)
        ],
      }}
    >
      {username.charAt(0)}
    </NanoAvatar>
  ) : (
    <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
      <Grid item>
        <NanoAvatar
          tooltip={username}
          loading={loading}
          sx={{
            ...(!withEmail ? { width: 32, height: 32 } : {}),
            ...avatarColorDef(theme)[
              workspaceRole?.hierarchy < 0 ? 0 : Math.trunc(workspaceRole?.hierarchy)
            ],
          }}
        >
          {username.charAt(0)}
        </NanoAvatar>
      </Grid>
      <Grid container xs={loading ? 8 : 'auto'} item direction="column">
        <Grid item display={loading ? 'block' : 'flex'} alignItems="baseline" gap={1}>
          <Typography variant={withEmail ? 'h5' : 'body1'} noWrap>
            {loading ? <Skeleton /> : `${username}`}
          </Typography>
          {me.idUser === idUser && (
            <Typography variant="body2" color="text.secondary" noWrap>
              ({t('you')})
            </Typography>
          )}
        </Grid>
        {withEmail && (
          <Grid item>
            <Typography variant="body2" color="text.secondary">
              {loading ? <Skeleton /> : email}
            </Typography>
          </Grid>
        )}

        {/* {withPhone && (
            <Grid item>
              <Typography variant='body2' color='text.secondary'>
                {loading ? <Skeleton /> : phone}
              </Typography>
            </Grid>
          )} */}
      </Grid>
    </Grid>
  )
}

UserAvatarAndName.propTypes = propTypes
UserAvatarAndName.defaultProps = defaultProps

export default UserAvatarAndName
