import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Skeleton, Stack, useTheme, useMediaQuery } from '@mui/material'
import {
  dateMediumWithoutYearMonthAndWithoutTimeUTC,
  dateMediumWithoutYearAndWithoutTime,
} from '../utils/dateUtils'
import Level from './Level'
import isNumber from '../utils/isNumber'

const propTypes = {
  loading: PropTypes.bool,
  day: PropTypes.number.isRequired,
  level: PropTypes.shape({
    percent: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
    }),
    filling: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
    }),
    timestamp: PropTypes.string,
  }),
  is_combined: PropTypes.bool.isRequired,
  is_fake: PropTypes.bool.isRequired,
  fromMap: PropTypes.bool,
}

const defaultProps = { loading: false, level: {}, fromMap: false }

function LevelForecast({ loading, day, level, is_combined: isCombined, is_fake: isFake, fromMap }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const xlarge = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Stack display="flex" direction="column" alignItems="center">
      <Level
        loading={loading}
        levelPercent={level.percent?.value}
        isBig={false}
        isCombined={isCombined}
        isFake={isFake}
      />
      <Stack spacing={1}>
        <Typography noWrap variant="body2" color="text.secondary">
          {loading ? (
            <Skeleton />
          ) : isNumber(level.filling?.value) ? (
            fromMap ? (
              dateMediumWithoutYearMonthAndWithoutTimeUTC(level.timestamp)
            ) : day === 1 ? (
              xlarge ? (
                t('tomorrow')
              ) : (
                dateMediumWithoutYearMonthAndWithoutTimeUTC(level.timestamp)
              )
            ) : xlarge ? (
              dateMediumWithoutYearAndWithoutTime(level.timestamp)
            ) : (
              dateMediumWithoutYearMonthAndWithoutTimeUTC(level.timestamp)
            )
          ) : (
            t('not_applicable')
          )}
        </Typography>
      </Stack>
      <Typography noWrap variant="h6">
        {loading ? <Skeleton /> : t('data_type_unit', { value: level.filling })}
      </Typography>
    </Stack>
  )
}

LevelForecast.propTypes = propTypes
LevelForecast.defaultProps = defaultProps

export default LevelForecast
