import React, { forwardRef } from 'react'
// import styled from 'styled-components';
import PropTypes from 'prop-types'
import { Paper } from '@mui/material'

const propTypes = { p: PropTypes.number, sx: PropTypes.shape({}), children: PropTypes.node }

const defaultProps = { p: 3, sx: {} }

/**
 * @type {import('react').ForwardRefExoticComponent<PropTypes.InferProps<propTypes>>}
 */
const NanoPaper = forwardRef((props, ref) => {
  const { sx, p, ...rest } = props
  return (
    <Paper
      sx={{
        border: '1px solid',
        backgroundColor: 'greyBackground.main',
        borderColor: 'grey.main',
        flexGrow: 1,
        boxShadow: 'none',
        p,
        ...sx,
      }}
      {...rest}
      ref={ref}
    >
      {props.children}
    </Paper>
  )
})

NanoPaper.propTypes = propTypes
NanoPaper.defaultProps = defaultProps

export default NanoPaper
