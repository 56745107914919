import dayjs from 'dayjs'
import i18n from '../../../config/i18n'

/**
 * Function to format X-axis ticks and show only if the day changed
 */
export const formatTimeTickLabel = (() => {
  // memorize the last formatted tick day
  let previousTickDay

  /**
   * @param {number} tickTime
   * @param {number} index
   * @returns {string}
   */
  return function _formatTimeTickLabel(tickTime, index) {
    const tickDay = dayjs(tickTime)
    const shouldDisplay = index === 0 || !tickDay.isSame(previousTickDay, 'day')
    const shouldDisplayMonth = index === 0 || !tickDay.isSame(previousTickDay, 'month')
    const shouldDisplayYear = index === 0 || !tickDay.isSame(previousTickDay, 'year')
    previousTickDay = tickDay

    if (!shouldDisplay) {
      return '' // display nothing
    } else if (shouldDisplayYear) {
      return new Date(tickTime).toLocaleDateString(i18n.resolvedLanguage, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    } else if (shouldDisplayMonth) {
      return new Date(tickTime).toLocaleDateString(i18n.resolvedLanguage, {
        month: 'short',
        day: 'numeric',
      })
    } else {
      return new Date(tickTime).toLocaleDateString(i18n.resolvedLanguage, { day: 'numeric' })
    }
  }
})()

const DAY = 24 * 60 * 60 * 1000 // ms
const WEEK = 7 * DAY // ms
const MONTH = 30 * DAY // ms
const YEAR = 365 * DAY // ms
const MIN_TICK_GAP = 90 // px

/**
 * Generate ticks for the X-axis, appropriate for the given data and graph width
 */
export function generateTimeTicks(timestamps, graphWidth) {
  const firstTime = timestamps[0]
  const lastTime = timestamps[timestamps.length - 1]
  const timeRange = lastTime - firstTime

  // Number of ticks that can fit within the graph width
  const maxTicks = Math.floor(graphWidth / MIN_TICK_GAP)

  // Determine the tick interval based on the time range and maxTicks
  const tickInterval =
    [
      1 * DAY,
      2 * DAY,
      3 * DAY,
      5 * DAY,
      1 * WEEK,
      1 * MONTH,
      2 * MONTH,
      3 * MONTH,
      4 * MONTH,
      6 * MONTH,
    ].find((interval) => timeRange / interval <= maxTicks) ?? YEAR

  // Generate the ticks based on the chosen interval, starting from the end
  const ticks = []
  let tick = lastTime
  while (tick >= firstTime) {
    ticks.unshift(tick)
    tick -= tickInterval
  }

  return ticks
}
