import i18n from '../../config/i18n'

/**
 * short numeric date without day and without time (not UTC)
 * Example : 9/10/2021
 * @param {Date} date
 * @returns {string}
 */
const dateShortWithoutTime = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}
/**
 * Medium date without year and without time (not UTC)
 * Example : mer. 10 sept.
 * @param {Date} date
 * @returns {string}
 */
const dateMediumWithoutYearAndWithoutTime = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })
}

/**
 * Medium date without year, time and day (not UTC)
 * Example : 10 sept.
 * @param {Date | string} date
 * @returns {string}
 */
const dateMediumWithoutYearTimeAndDay = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, { month: 'short', day: 'numeric' })
}

/**
 * Medium date without year and without time (timeZone UTC)
 * Example : mer. 10 sept.
 * @param {Date} date
 * @returns {string}
 */
const dateMediumWithoutYearAndWithoutTimeUTC = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    timeZone: 'utc',
  })
}

/**
 * Medium date without year, month and without time (timeZone UTC)
 * Example : mer. 10
 * @param {Date} date
 * @returns {string}
 */
const dateMediumWithoutYearMonthAndWithoutTimeUTC = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'short',
    day: 'numeric',
    timeZone: 'utc',
  })
}

/**
 * Medium date without time (not UTC)
 * Example : mer. 10 sept. 23
 * @param {Date} date
 * @returns {string}
 */
const dateMediumWithoutTime = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  })
}

/**
 * Medium date without time (UTC)
 * Example : mer. 10 sept. 23
 * @param {Date} date
 * @returns {string}
 */
const dateMediumWithoutTimeUTC = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    timeZone: 'utc',
  })
}

/**
 * Medium date with time (not UTC)
 * Example : mer. 10 sept. 23 à 09:50
 * @param {Date} date
 * @returns {string}
 */
const dateMediumWithTime = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

/**
 * Long date without time (not UTC)
 * Example : mercredi 10 septembre 2023
 * @param {Date} date
 * @returns {string}
 */
const dateLongWithoutTime = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

/**
 * Long date with time (not UTC)
 * Example : mercredi 10 septembre 2023 à 09:50
 * @param {Date} date
 * @returns {string}
 */
const dateLongWithTime = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}
/**
 * Medium date without year and without time (not UTC)
 * Example : mer. 10 sept.
 * @param {Date} date
 * @returns {string}
 */
const dateShortWithTimeandYear = (date) => {
  const dateObject = new Date(date)
  return dateObject.toLocaleDateString(i18n.resolvedLanguage, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

/**
 * Is the first date after the second one
 * @param {Date} dateA
 * @param {Date} dateB
 * @returns {boolean}
 */
const isAfter = (dateA, dateB) => dateA > dateB

/**
 * Is the first date before the second one
 * @param {Date} dateA
 * @param {Date} dateB
 * @returns {boolean}
 */
const isBefore = (dateA, dateB) => dateA < dateB

const weekday = Array.from({ length: 7 }, (_, index) => {
  const nextDate = new Date('1970-01-04')
  nextDate.setDate(nextDate.getDate() + index)
  const day = nextDate.toLocaleString('en-EN', { weekday: 'long' })
  let dayLocal = nextDate.toLocaleString(i18n.resolvedLanguage, { weekday: 'long' })
  let dayIndex = null
  dayLocal = dayLocal[0].toUpperCase() + dayLocal.slice(1)
  dayIndex = index
  return {
    day,
    dayLocal,
    dayIndex,
  }
})

const getGMT = () => {
  const date = new Date()
  const offset = date.getTimezoneOffset()
  const hoursOffset = -offset / 60 // Offset en heures
  return `GMT${hoursOffset >= 0 ? '+' : ''}${hoursOffset}`
}

export {
  dateShortWithoutTime,
  dateMediumWithoutYearTimeAndDay,
  dateMediumWithoutYearAndWithoutTime,
  dateMediumWithoutYearAndWithoutTimeUTC,
  dateMediumWithoutYearMonthAndWithoutTimeUTC,
  dateMediumWithoutTime,
  dateMediumWithoutTimeUTC,
  dateMediumWithTime,
  dateLongWithoutTime,
  dateLongWithTime,
  dateShortWithTimeandYear,
  isAfter,
  getGMT,
  isBefore,
  weekday,
}
