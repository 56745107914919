// @ts-ignore don't know how to fix this ESM error
import createClient from 'openapi-fetch'
import env from '../config/env.js'
import i18n from '../config/i18n.js'

let isRefreshing = false
let bufferQueue = []
// Maybe we could change the x-web-version hard coded to 2 with the version from the package json https://dmitriy-comarov.medium.com/app-version-from-package-json-with-react-and-webpack-84097bd3f4f9
// const sharedHeader = { workspace: env().workspace, 'x-web-version': '2' }
/** @type {import('openapi-fetch').HeadersOptions & {Authorization? : string}} */
const sharedHeader = {
  workspace: env().workspace,
  'x-web-version': '2',
  'x-web-product': 'binconnect',
}
/**
 *
 * @param {string} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */
const customFetchWithInterceptor = async (url, options) => {
  // If it is not the refresh url we check the authentication validity to call the refresh url
  if (!url.includes('/v1/token/refresh')) options = await tokenVerify(options)
  return fetch(url, options).then(async (response) => {
    if (!response.ok) {
      const error = await response.json()

      throw new Error(i18n.exists(error.code) ? error.code : error.message)
    }
    return response
  })
}

/**
 * @type {ReturnType<typeof createClient<import('../../openapiDoc.js').paths>>}
 * Client of the api
 * @see {@link https://github.com/drwpow/openapi-typescript/tree/main/packages/openapi-fetch openapi-fetch}
 * */
export const client = createClient({
  baseUrl: env().ENDPOINT,
  fetch: customFetchWithInterceptor,
  headers: sharedHeader,
})

/**
 * Connect user and return user object
 *
 * @param {{username: string, password: string}} payload
 * @param {boolean} rememberMe
 * @returns {Promise<import('../../openapiDoc.js').components['schemas']['me'] | false>} User object
 */
export async function loginRequest(payload, rememberMe = false) {
  const { data, error } = await client.POST('/v1/token', { body: payload })
  if (!error) {
    sharedHeader.Authorization = data.access
    storeRefreshToken(data.refresh, rememberMe)
    const userResponse = await client.GET('/v2/me', {})
    return userResponse.data
  } else throw new Error(error.message ? i18n.t(error.message) : i18n.t('api_common_error'))
}

/**
 * extract information from JWT token
 * @param {string} token jwt token
 * @return {{
 * user_id: string,
 * token_type: string,
 * iat: number,
 * exp: number,
 * jti: string
 * }}JWT token in json
 */
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

/**
 * Store the refresh token in local storage if remember was selected,
 * in session storage instead.
 * @param {string} refreshToken
 * @param {boolean} rememberMe - default false
 */
const storeRefreshToken = (refreshToken, rememberMe = false) => {
  if (rememberMe || window.localStorage.getItem('refresh')) {
    window.localStorage.setItem('refresh', refreshToken)
  } else {
    window.sessionStorage.setItem('refresh', refreshToken)
  }
}
/**
 * clear http headers and local and session storage
 */
export const clearSession = () => {
  delete sharedHeader.Authorization
  window.localStorage.removeItem('refresh')
  window.sessionStorage.removeItem('refresh')
}

const processQueue = (error, token = null) => {
  bufferQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  bufferQueue = []
}

const tokenVerify = (request) => {
  if (request.headers.has('Authorization')) {
    const decodedJwt = parseJwt(request.headers.get('Authorization'))
    if (decodedJwt.exp * 1000 < Date.now()) {
      if (isRefreshing) {
        // if already refreshing we put in temp queue
        return new Promise((resolve, reject) => {
          bufferQueue.push({ resolve, reject })
        })
          .then((token) => {
            request.headers.set('Authorization', token)
            return request
          })
          .catch((err) => Promise.reject(err))
      }
      isRefreshing = true

      return new Promise((resolve, reject) => {
        refresh()
          .then((token) => {
            processQueue(null, token)
            request.headers.set('Authorization', token)
            return resolve(request)
          })
          .catch((err) => {
            processQueue(err, null)
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }
  }
  return request
}

/**
 * Refresh auth token and store it in local storage
 * @returns {Promise<string>}
 */
export async function refresh() {
  console.log('try refresh')
  const refreshToken =
    window.localStorage.getItem('refresh') ?? window.sessionStorage.getItem('refresh')
  if (!refreshToken) throw new Error('NO_REFRESH')
  const { data, error } = await client.POST('/v1/token/refresh', {
    body: { refresh: refreshToken },
  })
  if (error) {
    console.error('error refreshing token', { error })
    clearSession()
    return null
  }
  sharedHeader.Authorization = data.access
  storeRefreshToken(data.refresh)
  console.log('try refresh', { data })
  return data.access
}

export async function getRequest(URL, options = {}) {
  // @ts-ignore
  return client.GET(`/${URL}`, { params: { query: options.params } })
}

export async function postRequest(URL, payload, config) {
  // @ts-ignore
  const response = await client.POST(`/${URL}`, { body: payload, ...config })
  return response
}

export async function patchRequest(URL, payload) {
  // @ts-ignore
  const response = await client.PATCH(`/${URL}`, { body: payload })
  return response
}

export async function deleteRequest(URL) {
  // @ts-ignore
  const response = await client.DELETE(`/${URL}`, {})
  return response
}
