import L from 'leaflet'

export const PinIcon = (color, stroke, missingWeight) =>
  new L.DivIcon({
    html: `
 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <circle cx="12" cy="12" r="11.5" fill=${color}  stroke=${stroke} />
  ${missingWeight !== null && missingWeight >= 0 ? `<text x='50%' y='57.5%' alignment-baseline='middle' text-anchor='middle' font-size='9' font-weight='bold' fill='black'>${Math.floor(missingWeight * 10) / 10}</text>` : ''}
</svg>`,
  })
