import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { client } from '../../../shared/apiClient'
import NanoDialog from '../../../shared/components/NanoDialog'
import SelectGroups from '../../../shared/components/SelectGroups'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    device_content: PropTypes.string.isRequired,
    groups: PropTypes.shape([]),
  }),
  withGroup: PropTypes.bool.isRequired,
}

const defaultProps = {
  isOpen: false,
  row: {},
}

function AdminDeviceContentModal({ isOpen, onClose, onSuccess, row, withGroup }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)
  const { handleSubmit, control } = useForm()
  const isUpdateMode = Object.keys(row).length > 0
  const handleClose = () => {
    // reset();
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayAlert(null)
    setIsLoading(true)

    const payload = {
      device_content: data.admin_feed_name,
      group_ids: withGroup ? data.group.map((gr) => gr.id) : [],
    }
    if (isUpdateMode) {
      client
        .PATCH('/v1/workspace/device-contents/{id}', {
          params: { path: { id: row.id } },
          body: { ...payload },
        })
        .then(() => {
          sendEvent('device_content_updated')
          setIsLoading(false)
          handleClose()
          onSuccess()
        })
        .catch((err) => {
          setDisplayAlert(err.message)
          setIsLoading(false)
        })
    } else {
      client
        .POST('/v1/workspace/device-contents', {
          body: { ...payload },
        })
        .then((res) => {
          sendEvent('device_content_created')
          setIsLoading(false)
          handleClose()
          onSuccess(res.data.operation)
        })
        .catch((err) => {
          setDisplayAlert(err.message)
          setIsLoading(false)
        })
    }
  }

  let defaultValues = {}
  if (isUpdateMode) {
    defaultValues = {
      ...row,
      groups: row.groups.map((g) => ({
        label: g.group_name,
        id: g.group_id,
        section: 'group',
        ...g,
      })),
    }
  }
  return !isOpen ? null : (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={isUpdateMode ? t('admin_feed_form_title_update') : t('create_new_device_content')}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="admin_feed_name"
            defaultValue={defaultValues.device_content ?? null}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('admin_feed_form_name_placeholder')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                required
                inputProps={{ maxLength: 255 }}
              />
            )}
          />
          {withGroup && (
            <Controller
              control={control}
              name="group"
              defaultValue={defaultValues.groups ?? []}
              render={({ field, fieldState: { error } }) => (
                <SelectGroups
                  selectAll
                  error={error}
                  onChange={(_, data) => field.onChange(data)}
                  value={field.value}
                  required={false}
                />
              )}
            />
          )}

          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton
            loading={isLoading}
            type="submit"
            loadingPosition="start"
            // startIcon={<Icon />} // temporary fix : // forum to fix the error message: https://github.com/mui/material-ui/issues/31235
            fullWidth
          >
            {t('validate')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

AdminDeviceContentModal.propTypes = propTypes
AdminDeviceContentModal.defaultProps = defaultProps

export default AdminDeviceContentModal
