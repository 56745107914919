/**
 * NUMBER utils
 * Here the logic is to define if the value is a number or a string with a number
 * We have to use an helper because isNan take into account falsy, truelsy and nullsy values
 * The only way we found for the moment is to check the type of 'value'.
 */

/**
 * check if it is a real number
 * @param {any} value
 * @returns
 */
const isNumber = (value) => {
  if (typeof value === 'number') return !Number.isNaN(value)
  if (typeof value === 'string') {
    return !isNaN(Number(value))
  }
  return false
}

export default isNumber
