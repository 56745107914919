/**
 * export utils
 * Here the logic is to export json data to csv
 * the first param is the json file expected
 * the second param is simply the filename
 */

const JSZIP = require('jszip')
const { saveAs } = require('file-saver') // Importez la fonction saveAs

/**
 * check if it is a real number
 * @param {object} json
 * @param {import("../../modules/dashboard/models/exportModel").ColumnDefinitionType[]} col
 * @param {string} filename
 * @param {string} delimiter
 */
const downloadCSV = (json, col, filename, delimiter = ';') => {
  const filenameCleaned = filename.replace(/[/\\:]/g, '_')
  const csv = jsonToCSV(json, col, delimiter)

  const blob = new window.Blob([csv], { type: 'text/csv;charset=utf-8' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = filenameCleaned
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * check if it is a real number
 * @param {object} json
 * @param {string} filename
 * @param {string} delimiter
 */
const downloadZip = (json, filename, delimiter = ';') => {
  const filenameCleaned = filename.replace(/[/\\:]/g, '_')
  const zip = new JSZIP()
  json.forEach((datum) => {
    if (datum.content.length > 0) {
      const csv = jsonToCSV(datum.content, datum.column, delimiter)
      zip.file(`${datum.name}.csv`, csv) // Utilisez un nom de fichier distinct pour chaque fichier CSV
    }
  })

  // Generate the zip content asynchronously
  zip
    .generateAsync({ type: 'blob' }) // Spécifiez le type blob
    .then((content) => {
      // Utilisez saveAs pour télécharger le fichier zip
      saveAs(content, `${filenameCleaned}.zip`)
    })
}

/**
  Creates a CSV from a 2D array representing rows and columns of the CSV.
  Will properly escape the strings according to the given delimiter.

  @param {Array} array

  const data = [
    ['name', 'age', 'city'],
    ['John Doe', 29, 'New York'],
    ['Jane Smith', 34, 'Los Angeles'],
    ['Mary Johnson', 45, 'Chicago']
  ];

  @param {string} delimiter
  @return {string}
 */

function createCSVFromArray(array, delimiter) {
  // Avoid any character in the string to be confused with a delimiter.
  // https://stackoverflow.com/questions/4617935/is-there-a-way-to-include-commas-in-csv-columns-without-breaking-the-formatting
  const charsToEscapeReg = new RegExp(`["${delimiter}]`)
  const escape = (str) => {
    if (charsToEscapeReg.test(str)) {
      return `"${str.replace(/"/g, '""')}"`
    } else {
      return str
    }
  }

  return array.map((row) => row.map(String).map(escape).join(delimiter)).join('\n')
}

/**
 * Convert Json to CSV
 * @param {object} jsonArray
 * @param {import("../../modules/dashboard/models/exportModel").ColumnDefinitionType[]} col
 * @param {string} delimiter
 */
const jsonToCSV = (jsonArray, col, delimiter) => {
  const headers = Object.keys(jsonArray[0])

  const headersTranslated = headers.map((h) => col.find((c) => c.value === h)?.label ?? h)

  const csvArray = [headersTranslated, ...jsonArray.map((json) => headers.map((h) => json[h]))]

  return createCSVFromArray(csvArray, delimiter)
}

export { downloadCSV, downloadZip, createCSVFromArray }
