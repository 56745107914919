import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Skeleton, Stack, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { client } from '../../../shared/apiClient'
import TopPage from '../../layout/TopPage'
import OrderForm from '../form/OrderForm'
import { ToastContext } from '../../../shared/contexts'
import Order from '../components/Order'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import { orderDatesToString } from '../utils/orderUtils'

const propTypes = {}

const defaultProps = {}

function OrderPage() {
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [order, setOrder] = useState({})
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState({ isOpen: false, orderId: null })
  const disableOrderEdition =
    useWorkspaceSettingsStore((state) => state.getSetting('disableOrderEdition'))?.value === 'true'
  const handleValidateOrder = (newValidated) => {
    setValidated(newValidated)
  }

  const getData = async () => {
    setLoading(true)
    client
      .GET('/v1/orders/{id}', { params: { path: { id } } })
      .then((response) => {
        setOrder(response.data)
      })
      .catch((err) => {
        !!err && navigate('/not_found')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOrderDidEdit = () => {
    toastContext.sendMessage(t('order_snackbar_alert_created'))
    getData()
  }

  return (
    <>
      <TopPage
        back
        actions={
          !loading &&
          !disableOrderEdition &&
          !validated &&
          order.status !== 'validated' && (
            <Button onClick={() => setForm({ isOpen: true, orderId: order.id })}>
              {t('edit')}
            </Button>
          )
        }
        renderTitle={
          <Stack justifyContent="space-between" direction="row">
            <Typography noWrap variant="h1">
              {loading ? (
                <Skeleton width={80} />
              ) : (
                t('order_title', { id: orderDatesToString({ order }) })
              )}
            </Typography>
          </Stack>
        }
      />
      <Order order={order} loading={loading} handleValidateOrder={handleValidateOrder} />

      {form.isOpen && (
        <OrderForm
          isOpen={form.isOpen}
          orderId={form.orderId}
          onClickCloseButton={() => {
            setForm({ isOpen: false, orderId: null })
          }}
          onSuccess={handleOrderDidEdit}
        />
      )}
    </>
  )
}

OrderPage.propTypes = propTypes
OrderPage.defaultProps = defaultProps

export default OrderPage
