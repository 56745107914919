import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Skeleton, Stack, useTheme, useMediaQuery } from '@mui/material'
import { SiloCombine } from '../icons/index'

const propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string,
  is_combined: PropTypes.bool.isRequired,
}

const defaultProps = { loading: false, status: null }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function LevelWithError({ loading, status, is_combined: isCombined, ...props }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const messageKey =
    status === 'pending' && !props?.metadata?.info_to_calibrate ? 'calibrating' : status

  return (
    <Stack flexShrink={3} display="flex" direction="row" alignItems="center" spacing={2}>
      <Stack>
        {!mobileFormat &&
          (loading ? (
            <Skeleton variant="rounded" width={39} height={80} />
          ) : isCombined ? (
            <SiloCombine />
          ) : (
            <svg width="39" height="80" viewBox="0 0 27 54" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 0C8.67157 0 8 0.671573 8 1.5C8 2.32843 8.67157 3 9.5 3H17.5C18.3284 3 19 2.32843 19 1.5C19 0.671573 18.3284 0 17.5 0H9.5ZM25 41H14.0745H2C0.895431 41 0 40.1046 0 39V16.4348C0 15.2276 0.436826 14.061 1.22982 13.1507L6.83514 6.71584C7.78471 5.62574 9.15964 5 10.6053 5H16.3947C17.8404 5 19.2153 5.62574 20.1649 6.71583L25.7702 13.1507C26.5632 14.061 27 15.2276 27 16.4348V39C27 40.1046 26.1046 41 25 41ZM25 53.6874V43.9787L13.2188 51.9999L2 44.3616V53.7187H0V43.3125H0.459142L0.000141144 43H26.4375L27 43V53.6874H25Z"
                fill={theme.palette.priorityLow.main}
              />
            </svg>
          ))}
      </Stack>
      <Stack display="flex">
        <Typography noWrap variant={mobileFormat ? 'h6' : 'h4'}>
          {loading ? <Skeleton /> : t(messageKey)}
        </Typography>
        {status !== 'problem' && (
          <Typography variant="body2" color="text.secondary">
            {loading ? <Skeleton /> : t(`level_error_description_${messageKey}`)}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

LevelWithError.propTypes = propTypes
LevelWithError.defaultProps = defaultProps

export default LevelWithError
