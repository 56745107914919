import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import statusFilterModel from '../models/statusFilter'
import NanoAutocomplete from './NanoAutocomplete'
import { Typography } from '@mui/material'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
}

const defaultProps = {
  value: null,
}

/**
 *@param {(PropTypes.InferProps<SelectStatus.propTypes> )} props
 */
function SelectStatus({ onChange, value, ...props }) {
  const { t } = useTranslation()
  const options = async () =>
    statusFilterModel.map((lvl) => ({
      label: t(lvl.name),
      id: lvl.value,
      section: 'status',
      ...lvl,
    }))
  return (
    <NanoAutocomplete
      label={t('sensor_status')}
      noOptionsText={
        <Typography sx={{ pt: 3 }} align="center">
          {t('no_status_to_add')}
        </Typography>
      }
      fetchOptions={() => options()}
      onChange={onChange}
      value={value}
      {...props}
    />
  )
}

SelectStatus.propTypes = propTypes
SelectStatus.defaultProps = defaultProps

export default SelectStatus
