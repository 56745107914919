import React from 'react'
import PropTypes from 'prop-types'
import FarmPin from './FarmPin'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func,
}

const defaultProps = {
  onClick: null,
}

function MarkerFarms({ data, onClick }) {
  return data.map((g, index) => {
    return <FarmPin key={index} position={g.position} poiName={g.group_name} onClick={onClick} />
  })
}

MarkerFarms.propTypes = propTypes
MarkerFarms.defaultProps = defaultProps

export default MarkerFarms
