import React from 'react'
import PropTypes from 'prop-types'
import { ArrowDownIcon, ArrowUpIcon } from '../icons'
import { useTheme } from '@mui/material'

const propTypes = { sortDirection: PropTypes.oneOf(['ASC', 'DESC']).isRequired }

const defaultProps = {}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const SortIndicator = ({ sortDirection }) => {
  const theme = useTheme()
  return sortDirection === 'ASC' ? (
    <ArrowUpIcon stroke={theme.palette.text.secondary} />
  ) : (
    <ArrowDownIcon fill={theme.palette.text.secondary} />
  )
}

SortIndicator.propTypes = propTypes
SortIndicator.defaultProps = defaultProps

export default SortIndicator
