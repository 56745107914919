import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { client } from '../apiClient'
import NanoAutocomplete from './NanoAutocomplete'
import UserAvatarAndName from './UserAvatarAndName'
import { IconButton, Stack, Typography } from '@mui/material'
import { AddIcon } from '../icons'

async function fetchOptions(search, filterUsers) {
  const query = {
    search,
  }
  // @ts-ignore TODO: fix api doc
  const responses = await client.GET('/v1/users', { params: { query } })
  return responses.data.results.filter(filterUsers).map((user) => ({
    label: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email,
    id: user.idUser,
    section: 'user',
    ...user,
  }))
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  filterUsers: PropTypes.func,
}

const defaultProps = {
  value: null,
  filterUsers: () => true,
}

function SelectEmailUser({ onChange, value, filterUsers, ...props }) {
  const { t } = useTranslation()
  return (
    <NanoAutocomplete
      label={t('email')}
      fetchOptions={(search) => fetchOptions(search, filterUsers)}
      onChange={onChange}
      value={value}
      {...props}
      noOptionsText={
        <Typography sx={{ pt: 3 }} align="center">
          {t('no_users_to_add')}
        </Typography>
      }
      renderOption={(props, option) => {
        return (
          <Stack direction="row" justifyContent="space-between" component="li" {...props}>
            <UserAvatarAndName withEmail {...option} />
            <IconButton
              sx={{ backgroundColor: 'secondary.main' }}
              onClick={(event) => {
                onChange(event, value ? value.concat([option]) : [option])
                event.stopPropagation()
              }}
            >
              <AddIcon stroke="white" />
            </IconButton>
          </Stack>
        )
      }}
      sx={{ maxHeight: 300, overflow: 'auto' }}
    />
  )
}

SelectEmailUser.propTypes = propTypes
SelectEmailUser.defaultProps = defaultProps

export default SelectEmailUser
