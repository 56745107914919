import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import SideBarHeader from './SideBarHeader'

const propTypes = { withLogo: PropTypes.bool }

const defaultProps = { withLogo: true }

/**
 * @param {(PropTypes.InferProps<LoadingPage.propTypes>)}
 */
function LoadingPage({ withLogo }) {
  return (
    // reproduce the sidebar behaviour to place the log at the same position
    <div style={{ width: 250, display: 'flex' }}>
      {withLogo && <SideBarHeader color="blue" />}

      <CircularProgress
        size={50}
        thickness={2}
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      />
    </div>
  )
}

LoadingPage.propTypes = propTypes
LoadingPage.defaultProps = defaultProps

export default LoadingPage
