import React from 'react'
import PropTypes from 'prop-types'
import { dateMediumWithoutTime } from '../../../shared/utils/dateUtils'
import NanoCharacteristics from '../../../shared/components/NanoCharacteristics'
import { useTranslation } from 'react-i18next'
import BatteryIconWithDescription from './BatteryIconWithDescription'

const propTypes = {
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
}

const defaultProps = {
  data: {},
  loading: true,
}

const columns = (d, t) => [
  {
    title: t('device_caracteristic_installation_date'),
    renderValue: d.device_install_date ? dateMediumWithoutTime(d.device_install_date) : '-',
  },
  ...(!d.is_combined
    ? [
        {
          title: t('battery'),
          renderValue: <BatteryIconWithDescription status={d.metadata?.battery_status} />,
        },
      ]
    : []),
  {
    title: t('supplements'),
    renderValue: '-',
  },
  {
    title: t('device_content_type'),
    renderValue: d.deviceContentType,
  },
  {
    title: t('device_caracteristic_last_delivery_date'),
    renderValue: d.fillingDate ? dateMediumWithoutTime(d.fillingDate) : '-',
  },
  {
    title: t('device_caracteristic_coordinates'),
    renderValue: d.position && `${d.position.position_latitude},${d.position.position_longitude}`,
    copy: true,
    gmaps: true,
  },
  {
    title: t('reference'),
    renderValue: d.device_reference,
    copy: true,
  },
]

const DeviceCharacteristics = ({ data, loading }) => {
  const { t } = useTranslation()

  return <NanoCharacteristics columns={columns(data, t)} loading={loading} />
}

DeviceCharacteristics.propTypes = propTypes
DeviceCharacteristics.defaultProps = defaultProps

export default DeviceCharacteristics
