import PropTypes from 'prop-types'
import { Chip, Stack } from '@mui/material'
import NanoDialog from './NanoDialog'
import { PoiIconBold } from '../icons/index'

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
}

const defaultProps = {
  value: [],
  title: null,
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function DisplayDeviceOrGroupDialog({ title, open, onClose, value }) {
  return (
    <NanoDialog title={title} open={open} onClose={onClose}>
      <Stack
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        {value.map((v) => {
          return (
            <Chip
              key={v.group_id}
              sx={{
                backgroundColor: 'grey.main',
              }}
              icon={<PoiIconBold />}
              label={v.label}
            />
          )
        })}
      </Stack>
    </NanoDialog>
  )
}

DisplayDeviceOrGroupDialog.propTypes = propTypes
DisplayDeviceOrGroupDialog.defaultProps = defaultProps

export default DisplayDeviceOrGroupDialog
