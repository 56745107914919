import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import './map.css'
import './control/Control.FullScreen.css'
import { MapContainer, TileLayer, useMap, ZoomControl } from 'react-leaflet'
import { FullscreenControl } from 'react-leaflet-fullscreen'
import { Box } from '@mui/material'
import MarkerFarms from './layer/MarkerFarms'
import MarkerDevices from './layer/MarkerDevices'
import LegendMap from './control/LengendMap'
import { useDashboardStore } from '../../../shared/store'
import LoadingMap from '../../../shared/components/LoadingMap'

// @TODO: follow this issue https://github.com/yuzhva/react-leaflet-markercluster/issues/187

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

const propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const defaultProps = {}

function DeviceMapView({ groups }) {
  const filterModel = useDashboardStore((state) => state.filterModel)
  const [dataLoading, setDataLoading] = useState(true)
  const data = useDashboardStore((state) => state.data)
  const pagination = useDashboardStore((state) => state.pagination)

  // @TODO: Add this logic to the store
  useEffect(() => {
    useDashboardStore.getState().emptyDashboard()
  }, [filterModel])

  // Load data page by page
  useEffect(() => {
    if (pagination.pageCount === undefined || pagination.page < pagination.pageCount) {
      setDataLoading(true)
      useDashboardStore.getState().fetchData(pagination.page + 1)
    } else {
      setDataLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page])

  const [resetBounds, setResetBounds] = useState(true)
  const [bounds, setBounds] = useState(null)
  const mapRef = useRef(null)
  const dist = [
    500000, 500000, 400000, 100000, 74000, 45000, 30000, 17000, 10000, 6000, 4000, 2000, 800, 450,
    250, 120, 50, 40, 15,
  ]
  const [distance, setDistance] = useState(5000)
  const [devices, setDevices] = useState([])
  const [farms, setFarms] = useState([])
  const [checkMissingWeight, setCheckMissingWeight] = useState(
    window.localStorage.getItem('settingsMap') === 'true'
  )
  const onChangeSettings = () => {
    const newcheckMissingWeight = !checkMissingWeight
    window.localStorage.setItem('settingsMap', newcheckMissingWeight.toString())
    setCheckMissingWeight(newcheckMissingWeight)
  }
  const updateDistance = () => {
    if (mapRef.current) {
      const zoom = mapRef.current.getZoom()
      if (zoom !== undefined) setDistance(dist[zoom])
    }
  }

  const handleZoomMarker = (pos) => {
    mapRef.current.setView([pos[0], pos[1]], 15)
  }

  useEffect(() => {
    const map = mapRef.current
    if (map) {
      updateDistance()
      map.on('zoomend', updateDistance)
      return () => {
        map.off('zoomend', updateDistance)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dist, mapRef])

  const MapBounds = () => {
    const map = useMap()
    if (resetBounds && bounds?.length) {
      // need the condition to prevent crash in fitBounds. Weir TODO: investigate
      map.fitBounds(bounds)
      setResetBounds(false)
      return null
    }
  }

  useEffect(() => {
    if (mapRef.current) {
      updateDistance()
    }
    // Remove the device without position to define the position for the poi
    const dataFiltered = data?.filter(
      (i) =>
        i.position.position_latitude !== undefined || i.position.position_longitude !== undefined
    )
    if (dataFiltered.length > 0) {
      setBounds(
        dataFiltered.map((i) => [i.position.position_latitude, i.position.position_longitude])
      )
      setResetBounds(true)
    }

    const deviceGroupIds = new Set(dataFiltered.map((device) => device.group_poi.group_poi_id))
    const deviceIds = new Set(dataFiltered.map((device) => device.device_id))
    const filteredFarms = groups
      .filter((g) => g.is_poi === true && deviceGroupIds.has(g.group_id))
      .map((farm) => {
        const devices = farm.devices
          .filter((device) => deviceIds.has(device.device_id))
          .filter((device) => device.device_fixed_lat !== null && device.device_fixed_lng !== null)

        let position = null
        if (devices.length > 0) {
          const totalLat = devices.reduce((sum, device) => sum + device.device_fixed_lat, 0)
          const totalLng = devices.reduce((sum, device) => sum + device.device_fixed_lng, 0)
          position = [totalLat / devices.length, totalLng / devices.length]
        }

        return {
          ...farm,
          devices,
          position,
        }
      })

    setFarms(filteredFarms)
    const dataWithNewPosition = data.map((device) => {
      const farm = filteredFarms.find((farm) => farm.group_id === device.group_poi.group_poi_id)

      if (farm && farm.position) {
        return {
          ...device,
          position: {
            ...device.position,
            position_latitude: farm.position[0],
            position_longitude: farm.position[1],
          },
        }
      }
      return device
    })
    setDevices(dataWithNewPosition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      {dataLoading ? (
        <LoadingMap
          withLogo={false}
          progress={
            pagination.pageCount && Math.round((pagination.page / pagination.pageCount) * 100)
          }
          heavy={pagination.rowCount > 100}
        />
      ) : (
        <>
          <Box style={{ height: '100%', width: '100%' }}>
            <MapContainer
              style={{ height: '100%', width: '100%', borderRadius: '10px' }}
              bounds={bounds}
              zoomControl={false}
              zoom={13}
              ref={mapRef}
              scrollWheelZoom
            >
              <ZoomControl position="topleft" />
              <MapBounds />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <FullscreenControl position="topleft" />
              {distance < 75000 && <MarkerFarms data={farms} onClick={handleZoomMarker} />}
              <MarkerDevices
                data={devices}
                distance={distance}
                missingWeight={checkMissingWeight}
              />
              <LegendMap
                value={checkMissingWeight}
                onChangeFreeSpace={onChangeSettings}
                zoom={mapRef?.current?.getZoom()}
                distance={dist[mapRef?.current?.getZoom()]}
              />
            </MapContainer>
          </Box>
        </>
      )}
    </>
  )
}
DeviceMapView.propTypes = propTypes
DeviceMapView.defaultProps = defaultProps

export default DeviceMapView
