import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import { ReactComponent as ArrowRightIconBig } from '../icons/ArrowRightIconBig.svg'

const propTypes = {
  text: PropTypes.string,
}

const defaultProps = { text: '' }

function ButtonNext({ text, ...props }) {
  return (
    <Button startIcon={<ArrowRightIconBig stroke="white" />} {...props}>
      {text}
    </Button>
  )
}

ButtonNext.propTypes = propTypes
ButtonNext.defaultProps = defaultProps

export default ButtonNext
