import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton, TableCell, Typography } from '@mui/material'

const propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.shape([]),
}

const defaultProps = { data: null, loading: false, variant: 'body', children: null }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function SimpleCell({ data, loading, variant, children, ...props }) {
  return (
    <TableCell {...props}>
      <Typography variant={variant}>{loading ? <Skeleton width="80%" /> : data}</Typography>
      {children}
    </TableCell>
  )
}

SimpleCell.propTypes = propTypes
SimpleCell.defaultProps = defaultProps

export default SimpleCell
