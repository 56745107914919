import React from 'react'
import { TableCell } from '@mui/material'
import LevelForecast from '../../../shared/components/LevelForecast'

const propTypes = {}

const defaultProps = {}

function LevelForecastCell({ ...row }) {
  return (
    <TableCell sx={{ pr: 2 }}>
      <LevelForecast {...row} />
    </TableCell>
  )
}

LevelForecastCell.propTypes = propTypes
LevelForecastCell.defaultProps = defaultProps

export default LevelForecastCell
