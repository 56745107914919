import React from 'react'
import PropTypes from 'prop-types'
import NanoDialog from '../../shared/components/NanoDialog'
import SearchDevice from '../../shared/components/SearchDevice'
import { getRequest } from '../../shared/apiClient'

// import { useSessionStore } from '../../shared/store';

const fetchOptions = async (search = undefined) => {
  const params = {
    search,
  }
  const response = await getRequest('v2/dashboard/', { params })
  const allDevices = response.data.map((dashboard) => ({
    label: dashboard.device_name,
    others: { ...dashboard },
  }))
  return [...allDevices]
}

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {
  isOpen: false,
}

function SearchDeviceModal({ isOpen, onClose }) {
  return (
    <NanoDialog
      open={isOpen}
      onClose={onClose}
      scroll="body"
      PaperProps={{
        sx: {
          width: '50%',
          minHeight: '45%',
          maxHeight: '90%',
          // Ajoutez une classe CSS ou un style pour définir la hauteur maximale
        },
      }}
    >
      <SearchDevice
        fetchOptions={(search) => fetchOptions(search)} // retrieve all the options (initial state, pagination)
      />
    </NanoDialog>
  )
}

SearchDeviceModal.propTypes = propTypes
SearchDeviceModal.defaultProps = defaultProps

export default SearchDeviceModal
