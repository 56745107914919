import React, { useState, useContext } from 'react'
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Stack, useTheme, useMediaQuery } from '@mui/material'
import { ToastContext } from '../../shared/contexts'
import { useSessionStore } from '../../shared/store'
import TopPage from '../layout/TopPage'
import { patchRequest } from '../../shared/apiClient'
import NanoPassword from '../../shared/components/NanoPassword'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import errorMessages from '../../shared/utils/errorMessagesApi'

const propTypes = {}

const defaultProps = {}

function EditPassword() {
  const { t } = useTranslation()
  const user = useSessionStore((state) => state.user)
  const { handleSubmit, control } = useForm()
  const [isValid, setIsValid] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(null)
  const toastContext = useContext(ToastContext)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const onSubmit = async (payload) => {
    setDisplayError(null)
    setIsLoading(true)
    patchRequest(`v1/users/${user.idUser}/`, payload)
      .then(() => {
        sendEvent('account_password_updated')
        toastContext.sendMessage(t('user_snackbar_settings_success'))
        return useSessionStore.getState().refreshMe()
      })
      .catch((err) => {
        console.log('err', err, err.message)
        setDisplayError(
          errorMessages(t).find((error) => error.message === err.message)?.code ??
            t('api_common_error')
        )
      })
      .finally(() => setIsLoading(false))
  }
  return (
    <>
      <TopPage
        back
        justifyContent="center"
        title={mobileFormat ? null : t('change_password_menu')}
      />
      <div
        style={{
          margin: 'auto',
          width: mobileFormat ? '90%' : '33%',
          padding: '10px',
          display: 'center',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box mt="30px" padding="2px" component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={3}>
            <Controller
              control={control}
              name="old_password"
              defaultValue=""
              rules={{ required: t('form_field_required_error_message') }}
              render={({ field, fieldState: { error } }) => (
                <NanoPassword
                  required
                  {...field}
                  label={t('old_password')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="new_password"
              defaultValue=""
              rules={{ required: t('form_field_required_error_message') }}
              render={({ field, fieldState: { error } }) => (
                <NanoPassword
                  required
                  {...field}
                  label={t('new_password')}
                  error={!!error}
                  helperText={error?.message}
                  validatePassword
                  isValid={setIsValid}
                />
              )}
            />
            {!!displayError && <Alert severity="error">{displayError}</Alert>}
            <LoadingButton loading={isLoading} type="submit" fullWidth disabled={!isValid}>
              {t('save_confirm_button')}
            </LoadingButton>
          </Stack>
        </Box>
      </div>
    </>
  )
}

EditPassword.propTypes = propTypes
EditPassword.defaultProps = defaultProps

export default EditPassword
