import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import NanoDialog from '../../shared/components/NanoDialog'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import { client } from '../../shared/apiClient'

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({
    group_id: PropTypes.string.isRequired,
    client_poi_id: PropTypes.string.isRequired,
    group_namme: PropTypes.string.isRequired,
  }),
}

const defaultProps = {
  isOpen: false,
  /** @type {[import('../../../openapiDoc').components['schemas']['group'][0], React.Dispatch<import('../../../openapiDoc').components['schemas']['group']>]} */
  row: null,
}

function AdminGroupModal({ isOpen, onClose, onSuccess, row }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)

  const { handleSubmit, control } = useForm()

  const handleClose = () => {
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayAlert(null)
    setIsLoading(true)
    client
      .PATCH('/v1/groups/{id}', {
        params: { path: { id: row.group_id } },
        body: {
          ...data,
          client_poi_id: data?.client_poi_id?.trim() !== '' ? data.client_poi_id : null,
        }, // api block update if client_poi_id is empty (line 133 => https://gitlab.com/nanocloud/aws/platform/aws-sls-api/-/blob/master/src/libs/models/psql/groups/Group.js)
      })
      .then((res) => {
        sendEvent('group_updated')
        onSuccess(res.data)
        handleClose()
      })
      .catch((err) => {
        setDisplayAlert(err.message)
        setIsLoading(false)
      })
  }

  return !isOpen ? null : (
    <NanoDialog open={isOpen} onClose={handleClose} title={t('group_form_title_update')}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="group_name"
            defaultValue={row.group_name}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('group_one')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                required
                inputProps={{ maxLength: 64 }}
              />
            )}
          />
          <Controller
            control={control}
            name="client_poi_id"
            defaultValue={row.client_poi_id ?? null}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('id_group')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                inputProps={{ maxLength: 255 }}
              />
            )}
          />
          <Controller
            control={control}
            name="comment"
            defaultValue={row.comment ?? null}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('comment')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                inputProps={{ maxLength: 255 }}
              />
            )}
          />
          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton loading={isLoading} type="submit" loadingPosition="start" fullWidth>
            {t('validate')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

AdminGroupModal.propTypes = propTypes
AdminGroupModal.defaultProps = defaultProps

export default AdminGroupModal
