import React from 'react'
// import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TopPage from '../layout/TopPage'
// import FilterBar from '../../shared/components/FilterBar'
import NotificationsList from './components/NotificationsList'

const propTypes = {}

const defaultProps = {}

function Notifications() {
  const { t } = useTranslation()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%' }}>
      <TopPage title={t('notifications')} />
      <NotificationsList />
    </div>
  )
}

Notifications.propTypes = propTypes
Notifications.defaultProps = defaultProps

export default Notifications
