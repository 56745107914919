import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { client } from '../apiClient'
import NanoAutocomplete from './NanoAutocomplete'
import { Typography } from '@mui/material'

async function fetchOptions(search = undefined) {
  // const params = { search, ordering: 'group_name' }
  const responses = await client.GET('/v1/devices', { params: { query: { is_combined: false } } })
  return responses.data.map((device) => ({
    label: `${device.device_name} - ${device.farm_name}`,
    id: device.device_reference,
    section: 'bin',
    ...device,
    device_content: null,
  }))
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
}

const defaultProps = {
  value: [],
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function SelectDevices({ onChange, value, ...props }) {
  const { t } = useTranslation()

  useEffect(() => {
    fetchOptions()
  }, [])

  const overrideProps = {}

  return (
    <NanoAutocomplete
      label={t('bin_other')}
      noOptionsText={
        <Typography sx={{ pt: 3 }} align="center">
          {t('no_device_to_add')}
        </Typography>
      }
      fetchOptions={() => fetchOptions()}
      onChange={onChange}
      value={value}
      openOnFocus
      selectOnFocus
      textFieldProps={{ autoFocus: true }}
      {...props}
      {...overrideProps}
    />
  )
}

SelectDevices.propTypes = propTypes
SelectDevices.defaultProps = defaultProps

export default SelectDevices
