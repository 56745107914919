import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton, useTheme } from '@mui/material'

const propTypes = {
  loading: PropTypes.bool,
  isBig: PropTypes.bool,
  isCombined: PropTypes.bool,
  isFake: PropTypes.bool,
  levelPercent: PropTypes.number, // in percent
}

const defaultProps = {
  loading: false,
  levelPercent: null,
  isBig: true,
  isCombined: false,
  isFake: false,
}

function Level({ levelPercent, loading, isBig, isCombined, isFake }) {
  const theme = useTheme()
  const width = isBig ? 39 : 22.5
  const height = isBig ? 80 : 42.5
  let color = theme.palette.priorityLow.contrastText
  if (levelPercent <= 50) color = theme.palette.priorityMedium.contrastText
  if (levelPercent <= 10) color = theme.palette.priorityHigh.contrastText

  return loading ? (
    <Skeleton variant="rounded" width={width} height={height} />
  ) : isCombined ? (
    // <svg width={width} height={height} viewBox='0 0 32 58' fill={theme.palette.priorityLow.main} xmlns='http://www.w3.org/2000/svg'>
    //   <path fillRule='evenodd' clipRule='evenodd' d='M14.5 0C13.6716 0 13 0.671573 13 1.5C13 2.32843 13.6716 3 14.5 3H22.5C23.3284 3 24 2.32843 24 1.5C24 0.671573 23.3284 0 22.5 0H14.5ZM30 41H19.0745H7C5.89543 41 5 40.1046 5 39V16.4348C5 15.2276 5.43683 14.061 6.22982 13.1507L11.8351 6.71584C12.7847 5.62574 14.1596 5 15.6053 5H21.3947C22.8404 5 24.2153 5.62574 25.1649 6.71583L30.7702 13.1507C31.5632 14.061 32 15.2276 32 16.4348V39C32 40.1046 31.1046 41 30 41ZM30 53.6874V43.9787L18.2188 51.9999L7 44.3616V53.7187H5V43.3125H5.45914L5.00014 43H31.4375L32 43V53.6874H30Z' fill-opacity='0.5' />
    //   <path fillRule='evenodd' clipRule='evenodd' d='M9.5 4C8.67157 4 8 4.67157 8 5.5C8 6.32843 8.67157 7 9.5 7H17.5C18.3284 7 19 6.32843 19 5.5C19 4.67157 18.3284 4 17.5 4H9.5ZM25 45H14.0745H2C0.895431 45 0 44.1046 0 43V20.4348C0 19.2276 0.436826 18.061 1.22982 17.1507L6.83514 10.7158C7.78471 9.62574 9.15964 9 10.6053 9H16.3947C17.8404 9 19.2153 9.62574 20.1649 10.7158L25.7702 17.1507C26.5632 18.061 27 19.2276 27 20.4348V43C27 44.1046 26.1046 45 25 45ZM25 58V47.9952L13.4375 55.9999L2 48.0817V58H0V47H0.437517L0.4375 47H26.4375L27 47V58H25Z' />
    //   {levelPercent > 75 && <path d='M2.125 21.249C2.125 20.6967 2.57272 20.249 3.125 20.249H24.1874C24.7397 20.249 25.1874 20.6967 25.1874 21.249V23.1865C25.1874 23.7388 24.7397 24.1865 24.1874 24.1865H3.125C2.57271 24.1865 2.125 23.7388 2.125 23.1865V21.249Z' fill={color} />}
    //   {levelPercent > 50 && <path d='M2.125 26.3145C2.125 25.7622 2.57272 25.3145 3.125 25.3145H24.1874C24.7397 25.3145 25.1874 25.7622 25.1874 26.3145V28.2519C25.1874 28.8042 24.7397 29.2519 24.1874 29.2519H3.125C2.57271 29.2519 2.125 28.8042 2.125 28.2519V26.3145Z' fill={color} />}
    //   {levelPercent > 25 && <path d='M2.125 31.375C2.125 30.8227 2.57272 30.375 3.125 30.375H24.1874C24.7397 30.375 25.1874 30.8227 25.1874 31.375V33.3125C25.1874 33.8648 24.7397 34.3125 24.1874 34.3125H3.125C2.57271 34.3125 2.125 33.8648 2.125 33.3125V31.375Z' fill={color} />}
    //   {levelPercent >= 0 && <path d='M2.125 36.4365C2.125 35.8842 2.57272 35.4365 3.125 35.4365H24.1874C24.7397 35.4365 25.1874 35.8842 25.1874 36.4365V38.374C25.1874 38.9263 24.7397 39.374 24.1874 39.374H3.125C2.57271 39.374 2.125 38.9263 2.125 38.374V36.4365Z' fill={color} />}
    // </svg>
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 59"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.4824 57.6829L-4.76837e-07 48.4634H26.9648L13.4824 57.6829Z" fill="#E7E6ED" />
      <rect y="48.4634" width="1.34824" height="10.5366" fill="#E7E6ED" />
      <rect x="25.6166" y="48.4634" width="1.34824" height="10.5366" fill="#E7E6ED" />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M16.4065 0C15.6791 0 15.0894 0.589674 15.0894 1.31707C15.0894 2.04447 15.6791 2.63415 16.4065 2.63415H24.5583C25.2857 2.63415 25.8754 2.04447 25.8754 1.31707C25.8754 0.589674 25.2857 0 24.5583 0H16.4065ZM21.1625 40.8296H32C33.1046 40.8296 34 39.9341 34 38.8296V16.5029C34 15.3001 33.5664 14.1375 32.7787 13.2285L27.3766 6.99427C26.4269 5.89826 25.0481 5.2686 23.5979 5.2686H17.3831C15.9304 5.2686 14.5495 5.90042 13.5997 6.99965L8.21662 13.2299C7.43183 14.1381 7 15.2984 7 16.4988V38.8296C7 39.9341 7.89543 40.8296 9 40.8296H21.1625Z"
        fill="#E7E6ED"
        fill-opacity="0.5"
      />
      <path
        d="M20.4824 52.6829L7 43.4634H33.9648L20.4824 52.6829Z"
        fill="#E7E6ED"
        fill-opacity="0.5"
      />
      <rect x="7" y="43.4634" width="1.34824" height="10.5366" fill="#E7E6ED" fill-opacity="0.5" />
      <rect
        x="32.6166"
        y="43.4634"
        width="1.34824"
        height="10.5366"
        fill="#E7E6ED"
        fill-opacity="0.5"
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M9.40652 5C8.67912 5 8.08945 5.58967 8.08945 6.31707C8.08945 7.04447 8.67912 7.63415 9.40652 7.63415H17.5583C18.2857 7.63415 18.8754 7.04447 18.8754 6.31707C18.8754 5.58967 18.2857 5 17.5583 5H9.40652ZM14.1625 45.8296H25C26.1046 45.8296 27 44.9341 27 43.8296V21.5029C27 20.3001 26.5664 19.1375 25.7787 18.2285L20.3766 11.9943C19.4269 10.8983 18.0481 10.2686 16.5979 10.2686H10.3831C8.93036 10.2686 7.54947 10.9004 6.5997 11.9997L1.21662 18.2299C0.431833 19.1381 0 20.2984 0 21.4988V43.8296C0 44.9341 0.89543 45.8296 2 45.8296H14.1625Z"
        fill="#E7E6ED"
      />
      {levelPercent >= 0 && !isFake && (
        <path d="M2.7 36.561C2.7 36.0087 3.14772 35.561 3.7 35.561H23.3C23.8523 35.561 24.3 36.0087 24.3 36.561V37.1951C24.3 37.7474 23.8523 38.1951 23.3 38.1951H3.7C3.14772 38.1951 2.7 37.7474 2.7 37.1951V36.561Z" />
      )}
      {levelPercent > 10 && !isFake && (
        <path d="M2.7 32.6098C2.7 32.0575 3.14772 31.6098 3.7 31.6098H23.3C23.8523 31.6098 24.3 32.0575 24.3 32.6098V33.2439C24.3 33.7962 23.8523 34.2439 23.3 34.2439H3.7C3.14772 34.2439 2.7 33.7962 2.7 33.2439V32.6098Z" />
      )}
      {levelPercent > 30 && !isFake && (
        <path d="M2.7 28.6585C2.7 28.1063 3.14772 27.6585 3.7 27.6585H23.3C23.8523 27.6585 24.3 28.1063 24.3 28.6585V29.2927C24.3 29.845 23.8523 30.2927 23.3 30.2927H3.7C3.14772 30.2927 2.7 29.845 2.7 29.2927V28.6585Z" />
      )}
      {levelPercent > 50 && !isFake && (
        <path d="M2.7 24.7073C2.7 24.155 3.14772 23.7073 3.7 23.7073H23.3C23.8523 23.7073 24.3 24.155 24.3 24.7073V25.3415C24.3 25.8937 23.8523 26.3415 23.3 26.3415H3.7C3.14772 26.3415 2.7 25.8937 2.7 25.3415V24.7073Z" />
      )}
      {levelPercent > 70 && !isFake && (
        <path d="M2.7 20.7561C2.7 20.2038 3.14772 19.7561 3.7 19.7561H23.3C23.8523 19.7561 24.3 20.2038 24.3 20.7561V21.3902C24.3 21.9425 23.8523 22.3902 23.3 22.3902H3.7C3.14772 22.3902 2.7 21.9425 2.7 21.3902V20.7561Z" />
      )}
      {levelPercent > 90 && !isFake && (
        <path d="M2.7 16.8049C2.7 16.2526 3.14772 15.8049 3.7 15.8049H23.3C23.8523 15.8049 24.3 16.2526 24.3 16.8049V17.439C24.3 17.9913 23.8523 18.439 23.3 18.439H3.7C3.14772 18.439 2.7 17.9913 2.7 17.439V16.8049Z" />
      )}
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27 54"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M9.40652 0C8.67912 0 8.08945 0.589674 8.08945 1.31707C8.08945 2.04447 8.67912 2.63415 9.40652 2.63415H17.5583C18.2857 2.63415 18.8754 2.04447 18.8754 1.31707C18.8754 0.589674 18.2857 0 17.5583 0H9.40652ZM14.1625 40.8296H25C26.1046 40.8296 27 39.9341 27 38.8296V16.5029C27 15.3001 26.5664 14.1375 25.7787 13.2285L20.3766 6.99427C19.4269 5.89826 18.0481 5.2686 16.5979 5.2686H10.3831C8.93036 5.2686 7.54947 5.90042 6.5997 6.99965L1.21662 13.2299C0.431833 14.1381 0 15.2984 0 16.4988V38.8296C0 39.9341 0.89543 40.8296 2 40.8296H14.1625Z"
        fill="#E7E6ED"
      />
      {levelPercent >= 0 && !isFake && (
        <path d="M2.7 36.561C2.7 36.0087 3.14772 35.561 3.7 35.561H23.3C23.8523 35.561 24.3 36.0087 24.3 36.561V37.1951C24.3 37.7474 23.8523 38.1951 23.3 38.1951H3.7C3.14772 38.1951 2.7 37.7474 2.7 37.1951V36.561Z" />
      )}
      {levelPercent > 10 && !isFake && (
        <path d="M2.7 32.6098C2.7 32.0575 3.14772 31.6098 3.7 31.6098H23.3C23.8523 31.6098 24.3 32.0575 24.3 32.6098V33.2439C24.3 33.7962 23.8523 34.2439 23.3 34.2439H3.7C3.14772 34.2439 2.7 33.7962 2.7 33.2439V32.6098Z" />
      )}
      {levelPercent > 30 && !isFake && (
        <path d="M2.7 28.6585C2.7 28.1063 3.14772 27.6585 3.7 27.6585H23.3C23.8523 27.6585 24.3 28.1063 24.3 28.6585V29.2927C24.3 29.845 23.8523 30.2927 23.3 30.2927H3.7C3.14772 30.2927 2.7 29.845 2.7 29.2927V28.6585Z" />
      )}
      {levelPercent > 50 && !isFake && (
        <path d="M2.7 24.7073C2.7 24.155 3.14772 23.7073 3.7 23.7073H23.3C23.8523 23.7073 24.3 24.155 24.3 24.7073V25.3415C24.3 25.8937 23.8523 26.3415 23.3 26.3415H3.7C3.14772 26.3415 2.7 25.8937 2.7 25.3415V24.7073Z" />
      )}
      {levelPercent > 70 && !isFake && (
        <path d="M2.7 20.7561C2.7 20.2038 3.14772 19.7561 3.7 19.7561H23.3C23.8523 19.7561 24.3 20.2038 24.3 20.7561V21.3902C24.3 21.9425 23.8523 22.3902 23.3 22.3902H3.7C3.14772 22.3902 2.7 21.9425 2.7 21.3902V20.7561Z" />
      )}
      {levelPercent > 90 && !isFake && (
        <path d="M2.7 16.8049C2.7 16.2526 3.14772 15.8049 3.7 15.8049H23.3C23.8523 15.8049 24.3 16.2526 24.3 16.8049V17.439C24.3 17.9913 23.8523 18.439 23.3 18.439H3.7C3.14772 18.439 2.7 17.9913 2.7 17.439V16.8049Z" />
      )}
      <path d="M13.4824 52.6829L-4.76837e-07 43.4634H26.9648L13.4824 52.6829Z" fill="#E7E6ED" />
      <rect y="43.4634" width="1.34824" height="10.5366" fill="#E7E6ED" />
      <rect x="25.6166" y="43.4634" width="1.34824" height="10.5366" fill="#E7E6ED" />
    </svg>
  )
}

Level.propTypes = propTypes
Level.defaultProps = defaultProps

export default Level
