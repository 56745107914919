import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css';
import * as Sentry from '@sentry/react'
import App from './App'
import env from './config/env'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { initAnalytics } from './shared/utils/analyticsUtils'

import './config/i18n'
console.log(
  `${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_VERSION} - env : ${process.env.NODE_ENV} , ${env().environment}`
)

// prevent sending error from local or dev
if (env().environment !== 'develop') {
  // init Analytics
  initAnalytics()

  // init Sentry
  Sentry.init({
    dsn: 'https://ac814f03321f539c219f2c3e60b39579@o1316695.ingest.sentry.io/4505834132668416',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/api\.nanolike\.io/],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    environment: env().environment,
    release: process.env.REACT_APP_VERSION,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })
}
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
