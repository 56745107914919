import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MoreAction from '../../../shared/components/MoreAction'
import { deleteRequest } from '../../../shared/apiClient'
import { DialogContext } from '../../../shared/contexts'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
}

const defaultProps = {}

function MoreActionAdminDrug({ id }) {
  const { t } = useTranslation()
  const dialogContext = useContext(DialogContext)

  const fetchData = () =>
    deleteRequest(`v1/workspace/drugs/${id}`).then(() => {
      sendEvent('drug_deleted')
      dialogContext.deletedOrEdited()
    })

  return (
    <MoreAction
      deleteItemFunction={fetchData}
      deleteItemAlertTitle={t('admin_drug_confirm_deletion_title')}
      deleteItemAlertDescription={t('admin_drug_confirm_deletion_description')}
      // onClickEdit={handleEdit} // WIP : Patch request does not exist yet
    />
  )
}

MoreActionAdminDrug.propTypes = propTypes
MoreActionAdminDrug.defaultProps = defaultProps

export default MoreActionAdminDrug
