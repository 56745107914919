import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import UserAvatarAndName from './UserAvatarAndName'

const propTypes = {
  loading: PropTypes.bool,
  source: PropTypes.oneOf(['manual', 'external', 'delivery', 'export', '']),
}

const defaultProps = { loading: false, source: '' }

/**
 * @param {PropTypes.InferProps<propTypes>} user
 */
function CalibrationCreatedByCell({ loading, source, ...user }) {
  const props = { ...user }
  return (
    <TableCell align="left">
      <UserAvatarAndName
        {...user}
        first_name={
          source === 'export'
            ? 'Export'
            : source === 'external'
              ? 'API'
              : props.is_nano_staff
                ? 'Nanolike'
                : props.first_name
        }
        last_name={
          source === 'export' || source === 'external'
            ? ''
            : props.is_nano_staff
              ? ''
              : props.last_name
        }
        loading={loading}
      />
    </TableCell>
  )
}

CalibrationCreatedByCell.propTypes = propTypes
CalibrationCreatedByCell.defaultProps = defaultProps

export default CalibrationCreatedByCell
