import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { client } from '../../../shared/apiClient'
import NanoDialog from '../../../shared/components/NanoDialog'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const defaultProps = {
  isOpen: false,
}

function AdminDrugModal({ isOpen, onClose, onSuccess }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)
  const { handleSubmit, control } = useForm()

  const handleClose = () => {
    // reset();
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayAlert(null)
    setIsLoading(true)

    client
      .POST('/v2/drugs', { body: { ...data } })
      .then(() => {
        sendEvent('drug_created')
        setIsLoading(false)
        handleClose()
        onSuccess()
      })
      .catch((err) => {
        setDisplayAlert(err.message)
        setIsLoading(false)
      })
  }

  return !isOpen ? null : (
    <NanoDialog open={isOpen} onClose={handleClose} title={t('create_new_drug')}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="drug"
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('drug')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                required
                inputProps={{ maxLength: 255 }}
              />
            )}
          />
          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton
            loading={isLoading}
            type="submit"
            loadingPosition="start"
            // startIcon={<Icon />} // temporary fix : // forum to fix the error message: https://github.com/mui/material-ui/issues/31235
            fullWidth
          >
            {t('validate')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

AdminDrugModal.propTypes = propTypes
AdminDrugModal.defaultProps = defaultProps

export default AdminDrugModal
