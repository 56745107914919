import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

const propTypes = {
  deviceContentType: PropTypes.shape({
    device_content_type: PropTypes.string,
    min_quantity_tons: PropTypes.number,
  }),
}

const defaultProps = {
  deviceContentType: null,
}

function DeviceContentTypeRules({ deviceContentType }) {
  const { t } = useTranslation()

  const isMinQuantityValidNumber = typeof deviceContentType?.min_quantity_tons === 'number'

  if (isMinQuantityValidNumber) {
    return (
      <Typography variant="caption" color="text.primary">
        {t('order_form_step_what_content_type_rule_header', {
          name: deviceContentType?.device_content_type,
        })}
        {isMinQuantityValidNumber && <br />}
        {t('order_form_step_what_content_type_rule_min_quantity_tons', {
          quantity: t('number_workspace_filling_unit', {
            value: deviceContentType?.min_quantity_tons,
          }),
        })}
      </Typography>
    )
  }

  return null
}

DeviceContentTypeRules.propTypes = propTypes
DeviceContentTypeRules.defaultProps = defaultProps

export default DeviceContentTypeRules
