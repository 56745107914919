import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, CircularProgress } from '@mui/material'

function progressForIndex({ step, nbSteps }) {
  if (step < 0 || step >= nbSteps) {
    throw new Error('bad index')
  }

  return { value: ((step + 1) / nbSteps) * 100, title: `${step + 1}/${nbSteps}` }
}

const propTypes = {
  step: PropTypes.number.isRequired,
  nbSteps: PropTypes.number.isRequired,
  darkMode: PropTypes.bool,
}

const defaultProps = {
  step: 0,
  nbSteps: 1,
  darkMode: false,
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function StepCircularProgress({ step, nbSteps, darkMode, ...props }) {
  const progress = progressForIndex({ step, nbSteps })

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: darkMode ? '#D0D0D033' : '#F0F4F5',
        }}
        size={40}
        thickness={4}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        color={darkMode ? undefined : 'secondary'}
        sx={{
          position: 'absolute',
          color: darkMode ? 'white' : undefined,
        }}
        size={40}
        thickness={4}
        value={progress.value}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" color={darkMode ? '#ffffff' : 'text.secondary'}>
          {progress.title}
        </Typography>
      </Box>
    </Box>
  )
}

StepCircularProgress.propTypes = propTypes
StepCircularProgress.defaultProps = defaultProps

export default StepCircularProgress
