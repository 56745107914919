import React from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import { useLocation, useNavigate } from 'react-router-dom'
import SidebarItem from './components/SidebarItem'
import SideBarHeader from '../../shared/components/SideBarHeader'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import {
  NotificationIcon,
  OrdersIcon,
  SettingsIcon,
  SiloIcon,
  UsersIcon,
} from '../../shared/icons/index'
import { useTheme, useMediaQuery } from '@mui/material'

const menuRoutes = [
  {
    i18nKey: 'menu_dashboard',
    icon: <SiloIcon />,
    path: 'devices',
  },
  {
    i18nKey: 'orders_title',
    icon: <OrdersIcon />,
    path: 'orders',
  },
  {
    i18nKey: 'menu_users',
    icon: <UsersIcon />,
    path: 'users',
  },
  {
    i18nKey: 'alerts_title',
    icon: <NotificationIcon />,
    path: 'alerts',
  },
  {
    i18nKey: 'menu_admin',
    icon: <SettingsIcon />,
    path: 'admin',
  },
]

const propTypes = {}

const defaultProps = {}

function Sidebar() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const xlarge = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'primary.dark',
          width: xlarge ? 104 : 80,
          overflowX: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        },
        width: xlarge ? 104 : 80,
      }}
    >
      <SideBarHeader xlarge={xlarge} />
      <List sx={{ p: 0, height: '100%' }}>
        <Stack justifyContent="space-between" height="100%">
          <Stack>
            {menuRoutes.map((route, index) => (
              <React.Fragment key={index}>
                <AccessRoleComponent resource={`menu_${route.path}`}>
                  <SidebarItem
                    key={route.i18nKey}
                    name={t(route.i18nKey)}
                    icon={route.icon}
                    selected={location.pathname.includes(route.path)}
                    onClick={() => navigate(route.path)}
                  />
                </AccessRoleComponent>
              </React.Fragment>
            ))}
          </Stack>
        </Stack>
      </List>
    </Drawer>
  )
}

Sidebar.propTypes = propTypes
Sidebar.defaultProps = defaultProps

export default Sidebar
