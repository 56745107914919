import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TopPage from '../../modules/layout/TopPage'
import { useNavigate } from 'react-router-dom'
import { AccessIllustration } from '../icons/index'

const propTypes = {}

const defaultProps = {}

function NotAccessPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <TopPage />
      <Grid container height="100%" alignItems="center" spacing={12} padding={12}>
        <Grid item container direction="column" spacing={5} xs={6}>
          <Grid item>
            <Typography variant="h1" fontSize={40}>
              {t('access_denied')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h1" fontSize={50}>
              {t('access_denied_title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">{t('access_denied_description')}</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                navigate('/user-help')
              }}
            >
              {t('button_support')}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <AccessIllustration />
        </Grid>
      </Grid>
    </>
  )
}

NotAccessPage.propTypes = propTypes
NotAccessPage.defaultProps = defaultProps

export default NotAccessPage
