import React from 'react'
// import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import RemainingDays from '../../../shared/components/RemainingDays'
const propTypes = {}
const defaultProps = {}

function RemainingDaysCell({ ...row }) {
  return (
    <TableCell sx={{ pl: 3, pr: 3 }}>
      <RemainingDays {...row} />
    </TableCell>
  )
}

RemainingDaysCell.propTypes = propTypes
RemainingDaysCell.defaultProps = defaultProps

export default RemainingDaysCell
