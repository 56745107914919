export const NotificationType = Object.freeze({
  Email: 'email',
  Push: 'push',
})

export const AlertDataType = Object.freeze({
  LevelPercent: 'level_percent',
  LevelTons: 'level_t',
  RemainingDays: 'remainingDays',
})
