import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import NanoDialog from '../../shared/components/NanoDialog'
import SelectGroups from '../../shared/components/SelectGroups'
import { client } from '../../shared/apiClient'
import { useSessionStore } from '../../shared/store'
import { emailReg } from '../../shared/utils/parseUtils'
import NanoSelectSingle from '../../shared/components/NanoSelectSingle'
import LangComponent from '../../shared/components/LangComponent'
import { sendEvent } from '../../shared/utils/analyticsUtils'

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
  optionsRole: PropTypes.shape({}),
}

const defaultProps = {
  isOpen: false,
  defaultValues: {},
}

function UserModal({ isOpen, onClose, onSuccess, defaultValues, optionsRole }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(null)
  const [userNoGroupWarning, setUserNoGroupWarning] = useState(false)
  const me = useSessionStore((state) => state.user)
  const [isAccess, setIsAccess] = useState(true)
  const isUpdateMode = Object.keys(defaultValues).length > 0
  const userLanguage = useSessionStore((state) => state.user.configuration.preferred_language)
  const defaultAccessValue = defaultValues?.group_memberships?.map((gm) => ({
    label: gm.group.group_name,
    id: gm.group.group_id,
    section: 'group',
    ...gm,
  }))
  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: {
      email: defaultValues.email,
      first_name: defaultValues.first_name,
      last_name: defaultValues.last_name,
      role: defaultValues?.workspace_role?.idRole ?? '',
      access: defaultAccessValue ?? [],
      language: defaultValues.preferred_language || userLanguage,
    },
  })
  const userRole = watch('role')
  const access = watch('access')
  useEffect(() => {
    if (optionsRole.find((r) => r.value === userRole)?.global) {
      setIsAccess(false)
      setValue('access', null, { shouldDirty: true })
    } else {
      setIsAccess(true)
    }
  }, [optionsRole, setValue, userRole])

  useEffect(() => {
    if (userNoGroupWarning && access?.length) setUserNoGroupWarning(false)
  }, [access, userNoGroupWarning])

  const handleClose = () => {
    reset()
    onClose()
    setDisplayError(null)
    setUserNoGroupWarning(false)
  }
  const onSubmit = (data) => {
    if (data?.access?.length === 0 && !userNoGroupWarning && isAccess) {
      setUserNoGroupWarning(true)
      return
    }
    setUserNoGroupWarning(false)
    setDisplayError(null)
    setIsLoading(true)
    const payloadUpdate = {
      preferred_language: data.language,
      idRole: data.role,
      first_name: data.first_name,
      last_name: data.last_name,
      // phone_number: data.phone_number
    }
    const payloadCreate = {
      email: data.email,
      lang: data.language,
      group_ids: data.access ? data.access.map((item) => item.id) : [],
      idRole: data.role,
      first_name: data.first_name,
      last_name: data.last_name,
      // phone_number: data.phone_number
    }

    const oldAccess = [...(defaultAccessValue ?? [])]
    const newAccess = [...(data.access ?? [])]
    if (isUpdateMode) {
      client
        .PATCH('/v1/users/{id}', {
          params: { path: { id: defaultValues.idUser } },
          body: { ...payloadUpdate },
        })
        .then(() => {
          return Promise.all([
            Promise.all(
              newAccess
                .filter((niu) => !oldAccess.map((g) => g.id).includes(niu.id))
                .map((g) =>
                  client.POST('/v1/users/{id}/group-memberships', {
                    params: { path: { id: defaultValues.idUser } },
                    body: {
                      group_id: g.id,
                    },
                  })
                )
            ),
            Promise.all(
              oldAccess
                .filter((old) => !newAccess.map((niu) => niu.id).includes(old.id))
                .map((g) =>
                  client.DELETE('/v1/users/{id}/group-memberships/{membershipId}', {
                    params: {
                      path: { id: defaultValues.idUser, membershipId: g.user_group_membership_id },
                    },
                  })
                )
            ),
          ])
        })
        .then(() => {
          sendEvent('account_profile_updated')
          setIsLoading(false)
          handleClose()
          onSuccess('update')
        })
        .catch((err) => {
          setDisplayError(err.message)
          setIsLoading(false)
        })
    } else {
      client
        .POST('/v1/invite', { body: payloadCreate })
        .then(() => {
          sendEvent('user_invited')
          setIsLoading(false)
          handleClose()
          onSuccess()
        })
        .catch((err) => {
          setDisplayError(t(err.message))
          setIsLoading(false)
        })
    }
  }
  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={isUpdateMode ? t('users_button_title_update') : t('users_button_title')}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: t('form_field_required_error_message'),
              pattern: { value: emailReg, message: t('respect_email_format') },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('email')}
                autoFocus
                disabled={!!defaultValues.email}
                error={!!error}
                helperText={error?.message}
                required
                type="email"
              />
            )}
          />
          {/* <Controller
            control={control}
            name='phone_number'
            defaultValue={defaultValues.phone_number}
            rules={{ pattern: { value: telReg, message: t('respect_phone_number_format') } }}// @TODO : add a patern regex for the phone number
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('phone_number')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                type='tel'
              />
            )}
          /> */}
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Controller
              control={control}
              name="first_name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('user_form_firstname')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="last_name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('user_form_name')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Stack>

          <Controller
            control={control}
            name="role"
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <NanoSelectSingle
                placeholder={t('role')}
                label={t('role')}
                variant="outlined"
                options={optionsRole}
                error={!!error}
                helperText={error?.message}
                required
                disabled={defaultValues?.idUser === me.idUser}
                {...field}
              />
            )}
          />
          {isAccess ? (
            <Controller
              control={control}
              name="access"
              rules={{
                required: !me.workspace_role?.global && t('form_field_required_error_message'),
              }}
              render={({ field, fieldState: { error } }) => (
                <SelectGroups
                  error={error}
                  onChange={(_, data) => field.onChange(data)}
                  value={field.value}
                  required={!me.workspace_role?.global} // mandatory if Admin create an account
                  textFieldProps={{
                    ...(userNoGroupWarning
                      ? {
                          color: 'warning',
                          helperText: t('user_form_userNoGroupWarning'),
                          focused: true,
                        }
                      : {}),
                  }}
                />
              )}
            />
          ) : null}

          <Controller
            control={control}
            name="language"
            render={({ field, fieldState: { error } }) => (
              <LangComponent
                label={t('user_form_language')}
                backgroundColor="grey.main"
                variant="outlined"
                error={!!error}
                {...field}
              />
            )}
          />
          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton loading={isLoading} type="submit" loadingPosition="start" fullWidth>
            {userNoGroupWarning ? t('user_form_add_farm_later') : t('confirm')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

UserModal.propTypes = propTypes
UserModal.defaultProps = defaultProps

export default UserModal
