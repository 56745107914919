import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { ReactComponent as ArrowRightIcon } from '../../../shared/icons/ArrowRightIconBig.svg'
import { ReactComponent as ChangePasswordIcon } from '../../../shared/icons/ChangePasswordIcon.svg'
import { ReactComponent as ConsIcon } from '../../../shared/icons/OrdersIcon.svg'
import { ReactComponent as LogoutIcon } from '../../../shared/icons/LogoutIcon.svg'
import { ReactComponent as UserIcon } from '../../../shared/icons/UserIcon.svg'
import { ReactComponent as SettingsIcon } from '../../../shared/icons/SettingsIcon.svg'
import { useSessionStore } from '../../../shared/store'
import NanoMenuItem from '../../../shared/components/NanoMenuItem'
import { ReactComponent as ArrowDownIcon } from '../../../shared/icons/ArrowDownIcon.svg'
import { ReactComponent as ArrowUpIcon } from '../../../shared/icons/ArrowUpIcon.svg'
import UserAvatarAndName from '../../../shared/components/UserAvatarAndName'
import { sendEvent } from '../../../shared/utils/analyticsUtils'
import { SupportIcon } from '../../../shared/icons/index'
import getUrl from '../../../shared/utils/urlCons'

const propTypes = { drawerOpen: PropTypes.bool.isRequired }

const defaultProps = {}

function UserMenu({ drawerOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { t } = useTranslation()
  const open = Boolean(anchorEl)
  const { user, currentClient } = useSessionStore((state) => state)
  const navigate = useNavigate()
  const options = useMemo(
    () => [
      {
        label: 'edit_profile_menu',
        icon: <UserIcon />,
        handleOnClick: () => navigate('/user-profile'),
      },
      {
        label: 'change_password_menu',
        icon: <ChangePasswordIcon />,
        handleOnClick: () => navigate('/user-password'),
      },
      {
        label: 'preferences_menu',
        icon: <SettingsIcon color="black" />,
        handleOnClick: () => navigate('/preferences'),
      },
      {
        label: 'help_menu',
        icon: <SupportIcon color="black" />,
        handleOnClick: () => navigate('/user-help'),
      },
      {
        label: 'terms_and_con_menu',
        icon: <ConsIcon color="black" />,
        handleOnClick: () => window.open(getUrl(currentClient.name.toLocaleLowerCase(), t)),
      },
      {
        label: 'logout_menu',
        icon: <LogoutIcon />,
        handleOnClick: () => {
          sendEvent('logout')
          useSessionStore.getState().logout()
        },
      },
    ],
    [currentClient.name, navigate, t]
  )
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <ListItem sx={{ p: '0px', ml: '16px' }}>
      <ListItemButton
        sx={{
          ':hover': {
            backgroundColor: 'transparent',
          },
          p: '0px',
        }}
        onClick={handleClickListItem}
      >
        <Stack
          direction="row"
          alignItems="center"
          alignContent="flex-start"
          justifyContent="flex-end"
        >
          <UserAvatarAndName {...user} avatarOnly avatarSize="normal" />
          {drawerOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </Stack>
      </ListItemButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: '100vh',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {options.map((option, index) => [
          <NanoMenuItem
            key={index}
            icon={option.icon}
            listItemTextProps={{ primary: t(option.label) }}
            onClick={option.handleOnClick}
          >
            <ArrowRightIcon />
          </NanoMenuItem>,
        ])}
      </Menu>
    </ListItem>
  )
}

UserMenu.propTypes = propTypes
UserMenu.defaultProps = defaultProps

export default UserMenu
