import React from 'react'
// import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import LastData from '../../../shared/components/LastData'
const propTypes = {}
const defaultProps = {}

function LastDataCell({ ...row }) {
  return (
    <TableCell>
      <LastData {...row} />
    </TableCell>
  )
}

LastDataCell.propTypes = propTypes
LastDataCell.defaultProps = defaultProps

export default LastDataCell
