import React from 'react'
// import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import LevelWithTonsAndMissingWeight from '../../../shared/components/LevelWithTonsAndMissingWeight'

const propTypes = {}

const defaultProps = {}

function LevelTodayCell({ ...row }) {
  return (
    <TableCell sx={{ pr: 3 }}>
      <LevelWithTonsAndMissingWeight {...row} />
    </TableCell>
  )
}

LevelTodayCell.propTypes = propTypes
LevelTodayCell.defaultProps = defaultProps

export default LevelTodayCell
