import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import { Alert, InputLabel, Stack, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import NanoDialog from '../../shared/components/NanoDialog'
import { postRequest, patchRequest } from '../../shared/apiClient'
import SelectGroups from '../../shared/components/SelectGroups'
import SelectLevel from '../../shared/components/SelectLevel'
import SelectDeviceContent from '../../shared/components/SelectDeviceContent'
import { ToastContext } from '../../shared/contexts'
import SelectRemainingDays from '../../shared/components/SelectRemainingDays'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import SelectStatus from '../../shared/components/SelectStatus'
import i18n from '../../config/i18n'
import errorMessages from '../../shared/utils/errorMessagesApi'
import NanoSelectSingle from '../../shared/components/NanoSelectSingle'
import batteryFilterModel from '../../shared/models/batteryFilterModel'

// import { useSessionStore } from '../../shared/store';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  filters: PropTypes.shape({
    searched: PropTypes.string,
    name: PropTypes.string,
    viewSelected: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          group_name: PropTypes.string,
          group_id: PropTypes.string,
        })
      ),
      PropTypes.shape({
        group_name: PropTypes.string,
        group_id: PropTypes.string,
      }),
    ]),
    deviceContentsFilters: PropTypes.arrayOf(PropTypes.shape({})),
    levelsFilters: PropTypes.arrayOf(PropTypes.shape({})),
    remainingDaysFilters: PropTypes.arrayOf(PropTypes.shape({})),
    groupsFilters: PropTypes.arrayOf(PropTypes.shape({})),
    statusFilters: PropTypes.arrayOf(PropTypes.shape({})),
    batteryFilters: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  updateId: PropTypes.string,
  viewName: PropTypes.string,
}

const defaultProps = {
  isOpen: false,
  onSuccess: null,
  filters: {
    deviceContentsFilters: [],
    levelsFilters: [],
    remainingDaysFilters: [],
    groupsFilters: [],
    statusFilters: [],
    batteryFilters: null,
  },
  updateId: null,
  viewName: null,
}

function CustomViewModal({ isOpen, onClose, onSuccess, filters, updateId, viewName }) {
  const toastContext = useContext(ToastContext)
  const { t } = useTranslation()
  const levelFiltersTranslated = filters.levelsFilters.map((lvl) => ({
    id: lvl.id,
    label: t(lvl.label),
    section: lvl.section,
  }))

  const statusFiltersTranslated = filters.statusFilters.map((lvl) => ({
    id: lvl.id,
    label: t(lvl.label),
    section: lvl.section,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(null)
  const { handleSubmit, control } = useForm()
  const cleanAfterSubmit = (payload) => {
    setIsLoading(false)
    onClose()
    onSuccess(payload)
    toastContext.sendMessage(t('custom_view_snackbar_created'))
  }
  const onSubmit = (data) => {
    const filterForPayload = {
      deviceContentIds: data.food.map((id) => id.id),
      levels: data.level.map((id) => id.id),
      remainingDays: data.days.map((id) => id.toString()),
      groupIds: data.group.map((id) => id.id),
      status: data.status.flatMap((id) => id.id),
      battery: data.battery,
    }
    const payload = {
      name: data.viewName,
      filters: filterForPayload,
    }
    console.log(
      'test ',
      Object.values(payload.filters).every((value) => value?.length === 0 || value === null)
    )
    console.log('payload ', payload.filters)
    if (Object.values(payload.filters).every((value) => value?.length === 0 || value === null)) {
      setDisplayError(t('custom_view_alert_filter'))
      return
    }
    setIsLoading(true)
    if (updateId) {
      patchRequest(`v2/custom-views/${updateId}/`, payload)
        .then(() => {
          sendEvent('custom_view_updated')
          cleanAfterSubmit(payload)
        })
        .catch((err) => {
          console.log(err)
          setDisplayError(
            i18n.exists(err.message)
              ? t(err.message)
              : (errorMessages(t).find((error) => error.message === err.message)?.code ??
                  t('api_common_error')),
            'error'
          )
          setIsLoading(false)
        })
    } else {
      postRequest('v2/custom-views', payload)
        .then(() => {
          sendEvent('custom_view_created')
          cleanAfterSubmit(payload)
        })
        .catch((err) => {
          setDisplayError(
            i18n.exists(err.message)
              ? t(err.message)
              : (errorMessages(t).find((error) => error.message === err.message)?.code ??
                  t('api_common_error')),
            'error'
          )
          setIsLoading(false)
        })
    }
  }
  return (
    <NanoDialog
      open={isOpen}
      onClose={onClose}
      title={!updateId ? t('custom_view_button_title') : t('custom_view_edit_button_title')}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="viewName"
            defaultValue={viewName}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('custom_view_form_name')}
                error={!!error}
                helperText={error?.message}
                required
                placeholder={t('custom_view_form_name_holder')}
                inputProps={{ maxLength: 254 }}
              />
            )}
          />
          <>
            <Controller
              control={control}
              name="food"
              defaultValue={filters.deviceContentsFilters}
              render={({ field }) => (
                <SelectDeviceContent
                  onChange={(_, data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                  label={t('device_content')}
                />
              )}
            />
            <Controller
              control={control}
              name="level"
              defaultValue={levelFiltersTranslated}
              render={({ field }) => (
                <SelectLevel
                  onChange={(_, data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                />
              )}
            />

            <Controller
              control={control}
              name="group"
              defaultValue={filters.groupsFilters}
              render={({ field }) => (
                <SelectGroups
                  onChange={(_, data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                />
              )}
            />

            <Controller
              control={control}
              name="status"
              defaultValue={statusFiltersTranslated}
              render={({ field }) => (
                <SelectStatus
                  onChange={(_, data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                />
              )}
            />

            <Controller
              control={control}
              name="battery"
              defaultValue={filters.batteryFilters?.value}
              render={({ field }) => (
                <NanoSelectSingle
                  options={[...batteryFilterModel]}
                  label={t('battery')}
                  variant="outlined"
                  placeholder={t('battery')}
                  handleClear={() => field.onChange(null)}
                  {...field}
                />
              )}
            />

            <Stack>
              <InputLabel id="test-select-label">{t('remaining_days')}</InputLabel>
              <Controller
                control={control}
                name="days"
                defaultValue={filters.remainingDaysFilters}
                render={({ field }) => (
                  <SelectRemainingDays
                    handleSelect={(data) => {
                      field.onChange(data)
                    }}
                    value={field.value}
                    withSelect={false}
                    selectProps={{ sx: { bgcolor: 'grey.main', height: 56 } }}
                  />
                )}
              />
            </Stack>
          </>
          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton loading={isLoading} type="submit" loadingPosition="start" fullWidth>
            {t('confirm')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

CustomViewModal.propTypes = propTypes
CustomViewModal.defaultProps = defaultProps

export default CustomViewModal
