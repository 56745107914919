import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { MoreHoriz } from '@mui/icons-material'
import { CircularProgress, IconButton, Menu } from '@mui/material'
import DialogConfirmAction from './DialogConfirmAction'
import { ToastContext } from '../contexts'
import { ReactComponent as TrashIcon } from '../icons/TrashIcon.svg'
import { ReactComponent as EditIcon } from '../icons/EditIcon.svg'
import NanoMenuItem from './NanoMenuItem'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

const propTypes = {
  onClickEdit: PropTypes.func,
  deleteItemFunction: PropTypes.func,
  deleteItemAlertTitle: PropTypes.string,
  deleteItemAlertDescription: PropTypes.string,
  deleteItemToastMessage: PropTypes.string,
}

const defaultProps = {
  onClickEdit: null,
  deleteItemFunction: null,
  deleteItemAlertTitle: null,
  deleteItemAlertDescription: null,
  deleteItemToastMessage: null,
}

function MoreAction({
  onClickEdit,
  deleteItemFunction,
  deleteItemAlertTitle,
  deleteItemAlertDescription,
  deleteItemToastMessage,
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openConfirm, setOpenConfirm] = useState(false)
  const toastContext = useContext(ToastContext)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e) => {
    setAnchorEl(null)
  }
  const handleEdit = (e) => {
    setAnchorEl(null)
    onClickEdit()
  }
  const handleDelete = (e) => {
    setAnchorEl(null)
    setOpenConfirm(true)
  }

  const handleConfirmDeletion = (e) => {
    setLoading(true)
    setOpenConfirm(false)
    deleteItemFunction()
      .then(() => {
        toastContext.sendMessage(deleteItemToastMessage ?? t('action_successful_deletion'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const items = []

  if (onClickEdit) {
    items.push(
      <NanoMenuItem
        sx={{ pt: 1, pb: 1 }}
        key="editLabel"
        listItemTextProps={{ primary: t('edit') }}
        onClick={handleEdit}
        icon={<EditIcon />}
      />
    )
  }

  if (deleteItemFunction) {
    items.push(
      <NanoMenuItem
        sx={{ pt: 1, pb: 1 }}
        key="deleteLabel"
        listItemTextProps={{ primary: t('delete') }}
        onClick={handleDelete}
        icon={<TrashIcon />}
      />
    )
  }

  return (
    <div>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <IconButton
          aria-label="more"
          onClick={(e) => {
            handleClick(e)
          }}
          style={{ zIndex: 1 }}
        >
          <MoreHoriz />
        </IconButton>
      )}
      {onClickEdit || deleteItemFunction ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          {items}
        </Menu>
      ) : null}
      <DialogConfirmAction
        open={openConfirm}
        title={deleteItemAlertTitle ?? t('delete')}
        description={deleteItemAlertDescription ?? t('action_deletion_default_description')}
        onClickCancel={(e) => setOpenConfirm(false)}
        onClickConfirm={(e) => handleConfirmDeletion(e)}
      />
    </div>
  )
}

MoreAction.propTypes = propTypes
MoreAction.defaultProps = defaultProps

export default MoreAction
