import i18n from '../../config/i18n'

const remainingDaysFilter = (t) =>
  [...Array(6).keys()].map((rd) => ({
    name: rd.toLocaleString(i18n.resolvedLanguage, {
      style: 'unit',
      unit: 'day',
      unitDisplay: 'narrow',
    }),
    label: rd.toLocaleString(i18n.resolvedLanguage, {
      style: 'unit',
      unit: 'day',
      unitDisplay: 'narrow',
    }),
    value: rd.toString(),
  }))

export default remainingDaysFilter
