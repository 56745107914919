import React, { useState, useContext, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, Stack, TextField, IconButton, useTheme, useMediaQuery } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import NanoPaper from './NanoPaper'
import NanoSection from './NanoSection'
import { patchRequest } from '../apiClient'
import { ToastContext } from '../contexts'
import { emailReg } from '../utils/parseUtils'
import { useWorkspaceSettingsStore } from '../store'
import { EditIcon } from '../icons/index'
import { sendEvent } from '../../shared/utils/analyticsUtils'

const propTypes = {}

const defaultProps = {}

function SupportPage() {
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [disabledEmail, setDisabledEmail] = useState(true)
  const [disabledPhone, setDisabledPhone] = useState(true)
  const toastContext = useContext(ToastContext)
  const { t } = useTranslation()
  const state = useWorkspaceSettingsStore()
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const validEmail = new RegExp(emailReg)

  useEffect(() => {
    setEmail(state.getSetting('support_email'))
    setPhone(state.getSetting('support_phone'))
  }, [state])

  const onChangePhone = () => {
    if (!disabledPhone) {
      patchRequest(`v1/workspace/settings/${phone.workspace_setting_id}`, phone).then(() => {
        sendEvent('workspace_setting_support_phone_updated')
        toastContext.sendMessage(t('phone_snackbar_success'))
      })
    }
    setDisabledPhone(!disabledPhone)
  }
  const onChangeEmail = () => {
    if (!disabledEmail) {
      if (validEmail.test(email.value)) {
        patchRequest(`v1/workspace/settings/${email.workspace_setting_id}`, email).then(() => {
          sendEvent('workspace_setting_support_email_updated')
          toastContext.sendMessage(t('email_snackbar_success'))
        })
      } else toastContext.sendMessage(t('respect_email_format'), 'error')
    }
    setDisabledEmail(!disabledEmail)
  }

  return (
    <NanoSection title={t('admin_support')}>
      <NanoPaper>
        {/* <Grid container spacing={{ xs: 2, md: 3 }}> */}
        <Stack
          p={2}
          direction="column"
          alignItems="flex-start"
          alignContent="center"
          justifyContent="space-between"
        >
          <Stack
            pb={2}
            direction="row"
            display="flex"
            alignItems="flex-end"
            alignContent="flex-end"
            justifyContent="space-between"
          >
            <TextField
              label={t('email_support')}
              sx={{ width: mobileFormat ? '100%' : 350 }}
              inputProps={{ readOnly: disabledEmail }}
              value={email?.value}
              type="email"
              onChange={(event) => {
                setEmail({ ...email, value: event.target.value })
                state.setDataSetting('support_email', event.target.value)
              }}
            />
            <IconButton
              onClick={() => {
                onChangeEmail()
              }}
            >
              {disabledEmail ? <EditIcon /> : <SaveIcon />}
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            display="flex"
            alignItems="flex-end"
            alignContent="flex-end"
            justifyContent="space-between"
          >
            <Box>
              <TextField
                label={t('phone_support')}
                sx={{ width: mobileFormat ? '100%' : 250 }}
                inputProps={{ readOnly: disabledPhone }}
                value={phone?.value}
                type="tel"
                onChange={(event) => {
                  setPhone({ ...phone, value: event.target.value })
                  state.setDataSetting('support_phone', event.target.value)
                }}
              />
            </Box>
            <IconButton
              onClick={() => {
                onChangePhone()
              }}
            >
              {disabledPhone ? <EditIcon /> : <SaveIcon />}
            </IconButton>
          </Stack>
        </Stack>
      </NanoPaper>
    </NanoSection>
  )
}

SupportPage.propTypes = propTypes
SupportPage.defaultProps = defaultProps

export default SupportPage
