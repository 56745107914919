import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Stack, TextField, Box } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import SelectDevicesAndGroups from '../../shared/components/selectDevicesOrGroups/SelectDevicesAndGroups'
import NanoDialog from '../../shared/components/NanoDialog'
import NanoSelectSingle from '../../shared/components/NanoSelectSingle'
import NanoSelectDate from '../../shared/components/NanoSelectDate'
import dayjs from 'dayjs'
import { client } from '../../shared/apiClient'
import { dateShortWithTimeandYear, getGMT } from '../../shared/utils/dateUtils'

import { downloadCSV, downloadZip } from '../../shared/utils/exportCsv'
import DialogConfirmAction from '../../shared/components/DialogConfirmAction'
import { columnLevel } from './models/exportModel'
import { useDashboardStore } from '../../shared/store'
const propTypes = {
  // onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
}

const defaultProps = { isOpen: false }

/**
 *  @param {PropTypes.InferProps<propTypes>} props
 */

const ExportForm = ({ isOpen, groups, onClose, onSuccess }) => {
  const { t } = useTranslation()
  const today = new Date(Date.now())
  const [dialogConfirmAction, setDialogConfirmAction] = useState([false, ''])
  const filterModel = useDashboardStore((state) => state.filterModel)

  // Hook form & dialog
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)
  const { handleSubmit, control, watch } = useForm()
  const data = watch()

  // Function to export

  /**
   * @param {array} data
   * @param {"," | "."} decimalDelimiter
   * @param {"," | ";" | " "| "\t"} data
   * @param {string} exportName
   */
  const exportLevel = (data, decimalDelimiter, exportName) => {
    const exportedData = data
      .flatMap(
        (d) =>
          d.data_type === 'level' &&
          d.data_points
            .filter((d) => d.value != null || d.level_t != null)
            .map((datum) => ({
              dateTime: dateShortWithTimeandYear(new Date(datum.timestamp)),
              device_reference: d.device_reference,
              device_name: d.device_name,
              level_percent:
                decimalDelimiter === ','
                  ? datum.level_percent.toLocaleString('fr-FR')
                  : datum.level_percent,
              level_t:
                decimalDelimiter === ',' ? datum.level_t.toLocaleString('fr-FR') : datum.level_t,
              missingWeight:
                decimalDelimiter === ','
                  ? datum.missingWeight.toLocaleString('fr-FR')
                  : datum.missingWeight,
            }))
      )
      .filter(Boolean)
    return { name: exportName, content: exportedData }
  }

  const getData = async (device, startDate, endDate) => {
    const dataType = ['level']
    try {
      const res = await client.POST('/v1/get-graph-data', {
        body: {
          device_ids: device,
          data_types: dataType,
          is_last_value: false,
          from_timestamp: startDate.toISOString(),
          to_timestamp: endDate.toISOString(),
        },
      })
      return res
    } catch (error) {
      console.log('error', error)
      setDisplayAlert(t('timed_out'))
    }
  }

  const onExport = async () => {
    const devicesInfo = data.device_groups_to_check_ids.flatMap((d) => {
      if (d.section === 'group') {
        return d.devices.flatMap((device) => {
          return {
            name: device.device_name,
            group: d.group_name,
            reference: device.device_reference,
          }
        })
      } else {
        return {
          name: d.device_name,
          group: d.farm_name,
          reference: d.device_reference,
        }
      }
    })
    const startDate = data.start_date.toDate()
    const endDate = data.end_date.toDate()

    const devices = data.device_groups_to_check_ids.flatMap((d) =>
      d.section === 'group'
        ? d.devices.map((device) => device.device_reference)
        : d.device_reference
    )

    const getDeviceInfo = (device) => {
      return devicesInfo.find((d) => d.reference === device) || {}
    }

    // Récupération de tous les résultats avec Promise.all
    Promise.all(
      devices.map((device) => {
        return getData([device], startDate, endDate).then((res) => {
          const { group, name } = getDeviceInfo(device)

          const exportDataPromises = []
          const levelData = exportLevel(
            res.data.data,
            data.decimal_delimiter,
            `${group}_${name}_level`
          )
          if (Object.keys(levelData).length > 0) {
            exportDataPromises.push(
              Promise.resolve({ ...levelData, column: columnLevel(t, getGMT()) })
            )
          }

          return Promise.all(exportDataPromises)
        })
      })
    )
      .then((exportDataArrays) => {
        // Flatten exportDataArrays into a single exportData array
        const exportData = exportDataArrays.flat()

        setIsLoading(false)

        if (exportData.length === 1) {
          downloadCSV(exportData[0].content, exportData[0].column, data.export_name, data.delimiter)
        } else if (exportData.length > 1) {
          downloadZip(exportData, `${data.export_name}`, data.delimiter)
        }

        onSuccess()
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error:', error)
        setDisplayAlert(t('timed_out'))
      })
  }

  const onSubmit = async () => {
    setDisplayAlert(null)
    setIsLoading(true)
    const devices = data.device_groups_to_check_ids.flatMap((d) =>
      d.section === 'group'
        ? d.devices.map((device) => device.device_reference)
        : d.device_reference
    )

    const startDate = data.start_date.toDate()
    const endDate = data.end_date.toDate()

    const nbDays = Math.trunc((endDate - startDate) / 86400000)

    if (nbDays * devices?.length * 24 > 10000) {
      // If too many devices/dates to export, prevent we have to wait.
      setDialogConfirmAction([true, 'warning'])
    } else if (nbDays * devices?.length * 24 > 20000) {
      // If too many devices/dates to export, prevent we can't export.
      setDialogConfirmAction([true, 'blocking'])
    } else {
      await onExport()
    }
  }

  return (
    <NanoDialog open={isOpen} onClose={onClose} title={t('export_csv')}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="export_name"
            defaultValue={`Export_${today.toLocaleDateString()}`}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('export_name')}
                autoFocus
                error={!!error}
                helperText={error?.message}
                required
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={
              filterModel.viewSelected.id && !filterModel.customViewSelected.id
                ? [
                    {
                      label: filterModel.viewSelected.name,
                      id: filterModel.viewSelected.id,
                      section: 'group',
                    },
                  ]
                : []
            }
            name="device_groups_to_check_ids"
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <SelectDevicesAndGroups
                label={t('export_form_select_device_or_group_of_device_placeholder')}
                error={error}
                onChange={(_, data) => {
                  field.onChange(data)
                }}
                value={field.value}
                required
                withCombined
              />
            )}
          />
          <Controller
            control={control}
            name="start_date"
            defaultValue={dayjs().add(-1, 'month')}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <NanoSelectDate
                {...field}
                fullWidth
                maxDate={dayjs()}
                minDate={dayjs().add(-2, 'year')}
                label={t('start_date')}
                error={!!error}
                disableFuture
                onChange={(data) => {
                  field.onChange(data)
                }}
                value={field.value}
                slotProps={{ textField: { error: !!error, helperText: error?.message } }}
              />
            )}
          />
          <Controller
            control={control}
            name="end_date"
            defaultValue={dayjs()}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <NanoSelectDate
                {...field}
                fullWidth
                maxDate={dayjs()}
                label={t('end_date')}
                error={!!error}
                disableFuture
                onChange={(data) => {
                  field.onChange(data)
                }}
                value={field.value}
                slotProps={{ textField: { error: !!error, helperText: error?.message } }}
              />
            )}
          />

          <Controller
            control={control}
            defaultValue="."
            name="decimal_delimiter"
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <NanoSelectSingle
                options={[
                  { value: ',', name: ',' },
                  { value: '.', name: '.' },
                ]}
                label={t('decimal_delimiter')}
                variant="outlined"
                placeholder={t('decimal_delimiter')}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            defaultValue=";"
            name="delimiter"
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <NanoSelectSingle
                options={[
                  { value: ',', name: ',' },
                  { value: ';', name: ';' },
                  { value: '\t', name: t('tabulation') },
                  { value: ' ', name: t('space') },
                ]}
                label={t('delimiter')}
                variant="outlined"
                placeholder={t('delimiter')}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          {!!displayError && <Alert severity="error">{displayError}</Alert>}
          <LoadingButton loading={isLoading} type="submit" loadingPosition="start" fullWidth>
            {t('export_csv')}
          </LoadingButton>
        </Stack>
      </Box>
      <DialogConfirmAction
        title={t('export_take_time_title')}
        open={dialogConfirmAction[0]}
        description={
          dialogConfirmAction[1] === 'warning'
            ? t('export_take_time_description')
            : t('export_impossible_description')
        }
        onClickCancel={() => {
          setDialogConfirmAction([false, null])
          setIsLoading(false)
        }}
        onClickConfirm={
          dialogConfirmAction[1] === 'warning'
            ? async () => {
                setDialogConfirmAction([false, null])
                await onExport()
                onClose()
              }
            : null
        }
        deleteMode={false}
      />
    </NanoDialog>
  )
}

ExportForm.defaultProps = defaultProps
ExportForm.propTypes = propTypes

export default ExportForm
