import React from 'react'
// import PropTypes from 'prop-types'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSessionStore } from '../../shared/store'

const propTypes = {}

const defaultProps = {}

/**
 * Everything here is for unauthenticated user
 */
const Anonymous = () => {
  const connected = useSessionStore((state) => state.connected)
  const user = useSessionStore((state) => state.user)
  const location = useLocation()
  const from = location.state?.from?.pathname ?? '/'
  // we get the potential from pathname . It can happens at the end of signin.
  // as the layout and anoymous are rendered simultaneously based on the connected flag , if it re rendered here first we go back to layout
  if (connected && user) return <Navigate to={from} replace />
  return (
    <>
      <Outlet />
    </>
  )
}

Anonymous.propTypes = propTypes
Anonymous.defaultProps = defaultProps

export default Anonymous
