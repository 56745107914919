import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { client } from '../../shared/apiClient'
import TopPage from '../layout/TopPage'
import NanoPaper from '../../shared/components/NanoPaper'
import NanoSection from '../../shared/components/NanoSection'
import AlertHistorySection from './AlertHistorySection'
import AlertStateCell from './models/cells/AlertStateCell'
import AlertTypeCell from './models/cells/AlertTypeCell'
import AlertDetailsCell from './models/cells/AlertDetailsCell'
import GroupsDevicesCell from '../../shared/components/GroupsDevicesCell'
import AlertDateCell from './models/cells/AlertDateCell'
import AlertDateModificationCell from './models/cells/AlertDateModificationCell'
import AlertRecipientsCell from './models/cells/AlertRecipientsCell'
import { useUserStore } from '../../shared/store'
import UserAvatarAndName from '../../shared/components/UserAvatarAndName'
import NanoCharacteristics from '../../shared/components/NanoCharacteristics'

const propTypes = {}

// @FIXME : Remove cell, we aren't in a table
const columns = (d, loading, users, t, fetchData) => [
  {
    title: t('state'),
    renderValue: (
      <AlertStateCell
        isText
        loading={loading}
        // Refresh data after state change
        onUpdated={fetchData}
        {...d}
      />
    ),
  },
  {
    title: t('type'),
    renderValue: <AlertTypeCell loading={loading} {...d} />,
  },

  {
    title: t('alerts_detail'),
    renderValue: <AlertDetailsCell loading={loading} {...d} />,
  },
  {
    title: t('menu_dashboard'),
    renderValue: <GroupsDevicesCell loading={loading} doubleChip {...d} />,
  },

  {
    title: t('alerts_last_triggered'),
    renderValue: <AlertDateCell loading={loading} {...d} />,
  },

  {
    title: t('created_at'),
    renderValue: <AlertDateModificationCell loading={loading} {...d} />,
  },
  {
    title: t('created_by'),
    renderValue: <UserAvatarAndName loading={loading} {...d.created_by} />,
  },

  {
    title: t('alerts_recipients'),
    renderValue: <AlertRecipientsCell loading={loading} users={users} {...d} />,
  },
]

function AlertPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  /** @type {[import('../../../openapiDoc').components['schemas']['alert'], React.Dispatch<import('../../../openapiDoc').components['schemas']['alert']>]} */
  const [data, setData] = useState({})
  const { id } = useParams()
  const [dataLoading, setDataLoading] = useState(false)
  const users = useUserStore((state) => state.dataUser)
  const [notifications, setNotifications] = useState([])

  const fetchData = () => {
    setDataLoading(true)
    return Promise.all([
      client.GET('/v1/alerts/{id}', { params: { path: { id } } }),
      client.GET('/v1/alerts/{id}/notifications', {
        params: {
          path: { id },
          query: { status: 'alert_validated', ordering: '-notification_timestamp' },
        },
      }),
    ])
      .then((responses) => {
        if (responses.length === 2) {
          const alert = responses[0].data
          if (responses[0].data.length === 0) navigate('/not_found')
          const owner = users?.find((user) => user.idUser === alert.created_by_id)
          // @ts-ignore created_by is messing wuth not able to fix
          setData({
            ...alert,
            created_by: `${owner?.first_name ?? '-'} ${owner?.last_name ?? '-'}`,
          })
          setNotifications(responses[1].data.results)
        }
      })
      .catch((err) => {
        !!err && navigate('/not_found')
      })
      .finally(() => {
        setDataLoading(false)
      })
  }

  useEffect(() => {
    setDataLoading(true)
    Promise.all([useUserStore.getState().fetchData(), fetchData()]).finally(() => {
      setDataLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TopPage back title={data.alert_name} />
      <NanoSection title={t('alerts_detail')}>
        <NanoCharacteristics
          loading={dataLoading}
          columns={columns(data, dataLoading, users, t, fetchData)}
        />
      </NanoSection>

      {!!notifications.length && (
        <NanoSection title={t('alerts_trigger_history')}>
          <NanoPaper>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {notifications.map((n, nIndex) =>
                n.offending_devices.map((od, dIndex) => (
                  <AlertHistorySection
                    key={dIndex}
                    loading={dataLoading}
                    notification_timestamp={n.notification_timestamp}
                    alertType={n.data_type_from_alert}
                    alertName={data.alert_name}
                    lastIndex={
                      nIndex === notifications.length - 1 &&
                      dIndex === n.offending_devices.length - 1
                    }
                    {...od}
                  />
                ))
              )}
            </Grid>
          </NanoPaper>
        </NanoSection>
      )}
    </>
  )
}
AlertPage.propTypes = propTypes
// AlertPage.defaultProps = defaultProps;

export default AlertPage
