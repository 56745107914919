import React from 'react'
import PropTypes from 'prop-types'
import { Slider, SliderThumb } from '@mui/material'

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
}

const defaultProps = {}

function ThumbComponent(props) {
  const { children, ...other } = props
  return (
    <SliderThumb {...other}>
      {children}
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </SliderThumb>
  )
}

ThumbComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

function NanoSlider({ ...props }) {
  return <Slider {...props} slots={{ thumb: ThumbComponent }} />
}

NanoSlider.propTypes = propTypes
NanoSlider.defaultProps = defaultProps

export default NanoSlider
