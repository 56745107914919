import React, { forwardRef } from 'react'
// import PropTypes from 'prop-types'
import NanoSelectSingle from './NanoSelectSingle'
import { flagMapping, langs } from '../utils/langUtils'
import i18n from '../../config/i18n'
import { ArrowDownIcon } from '../icons'

const getLangOptions = () => {
  const options = langs.map((lang) => {
    const languageNames = new Intl.DisplayNames([lang], { type: 'language' })
    return {
      value: lang,
      name: languageNames.of(lang)[0].toUpperCase() + languageNames.of(lang)?.slice(1), // force capital first letter
      icon: (
        <img
          src={`https://flagcdn.com/24x18/${flagMapping(lang)}.png`}
          width="24"
          height="18"
          alt={lang}
        />
      ),
    }
  })
  options.sort((o1, o2) => o1.name.localeCompare(o2.name))
  return options
}

const propTypes = {}

const defaultProps = {}

/**
 * @type {import('react').ForwardRefExoticComponent<import('prop-types').InferProps<propTypes>>}
 */
const LangComponent = forwardRef((props, ref) => {
  return (
    <NanoSelectSingle
      ref={ref}
      options={getLangOptions()}
      // Force to send all props of selectprops due to conception of NanoSelectSingle
      SelectProps={{
        IconComponent: ArrowDownIcon,
        sx: {
          backgroundColor: props.backgroundColor ?? 'transparent',
        },
        renderValue: (value) => {
          const option = getLangOptions().find((l) => l.value === value)
          return (
            <div style={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
              <div style={{ minWidth: 36 }}>{option?.icon ?? ''}</div>
              {option?.name}
            </div>
          )
        },
      }}
      // These are overriden by {...props} if used in react hook form
      onChange={(event) => {
        i18n.changeLanguage(event.target.value)
      }}
      value={i18n.resolvedLanguage} // always use the current lang displayed on the plateform
      {...props} // this allow usage with react hook form
    />
  )
})

LangComponent.propTypes = propTypes
LangComponent.defaultProps = defaultProps

export default LangComponent
